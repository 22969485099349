import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withMsal } from "@azure/msal-react";
import { Col } from "react-bootstrap";
import "./App.css";

import HomePage from "./components/pages/HomePage";
import NewsPage from "./components/pages/NewsPage";
import ManualPage from "./components/pages/ManualPage";
import SchedulePage from "./components/pages/SchedulePage";
import ServicesPage from "./components/pages/ServicesPage";
import EventsPage from "./components/pages/EventsPage";
import SettingsPage from "./components/pages/SettingsPage";
import NotificationPage from "./components/pages/NotificationPage";
import Page404 from "./components/pages/Page404";
import AccessibilityPage from "./components/pages/AccessibilityPage";
import Header from "./components/Header";

import { fetchGET } from "./apiHelper";

import PWAPrompt from "react-ios-pwa-prompt";

import Footer from "./components/Footer";

const HederWraped = withMsal(Header);

export default class App extends React.Component {
  state = {
    slovarBesed: [],
    jezik: "",
    dnevnaObvestila: [],
    dnevnaObvestilaVideno: {
      videno: true,
    },
  };

  componentDidMount() {
    this.getJezik();
    this.getSlovarBesed();
    this.getNotificationEvents();
  }

  getJezik = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` + `/api/student/jezik`
      );
      this.setState({ jezik: response }, this.getNastavitveAplikacije);
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getNastavitveAplikacije = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/nastavitve_aplikacije`
      );
      if (response[0].dostopnost === true) {
        if (this.state.jezik === "slo") {
          document.getElementById("dostopnost").style.display = "block";
          document.getElementById("dostopnostEng").style.display = "none";
        } else {
          document.getElementById("dostopnost").style.display = "none";
          document.getElementById("dostopnostEng").style.display = "block";
        }
      } else {
        document.getElementById("dostopnostEng").style.display = "none";
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getSlovarBesed = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` + `/api/sifrant/slovar`
      );
      const slovarBesedReduced = response.reduce((acc, obj) => {
        acc[obj.oznaka] = { naziv: obj.naziv };
        return acc;
      }, {});

      this.setState({ slovarBesed: slovarBesedReduced });
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getNotificationEvents = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/notifications_videno`
      );

      this.setState({ dnevnaObvestilaVideno: response });
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  render() {
    return (
      <React.StrictMode>
        <div>
          <div className="left-bg">
            <div className="aa1"></div>
            <div className="aa2"></div>
          </div>
          <div className="right-bg">
            <div className="aa3"></div>
            <div className="aa4"></div>
          </div>
          <Col lg={5} style={{ margin: "0 auto", padding: "0" }}>
            <div>
              <BrowserRouter>
                <HederWraped
                  slovarBesed={this.state.slovarBesed}
                  obvestilaVidena={this.state.dnevnaObvestilaVideno}
                  funkcijaPreveriObvestila={this.getNotificationEvents}
                />
                <Routes>
                  <Route
                    path="/"
                    exact={true}
                    element={<HomePage slovarBesed={this.state.slovarBesed} />}
                  />
                  <Route
                    path="/novice"
                    element={<NewsPage slovarBesed={this.state.slovarBesed} />}
                  />
                  <Route
                    path="/navodila"
                    element={
                      <ManualPage slovarBesed={this.state.slovarBesed} />
                    }
                  />
                  <Route
                    path="/koledar"
                    element={
                      <SchedulePage slovarBesed={this.state.slovarBesed} />
                    }
                  />
                  <Route
                    path="/storitve"
                    element={
                      <ServicesPage slovarBesed={this.state.slovarBesed} />
                    }
                  />
                  <Route
                    path="/dogodki"
                    element={
                      <EventsPage slovarBesed={this.state.slovarBesed} />
                    }
                  />
                  <Route
                    path="/obvestila"
                    element={
                      <NotificationPage slovarBesed={this.state.slovarBesed} />
                    }
                  />
                  <Route
                    path="/nastavitve"
                    element={
                      <SettingsPage
                        slovarBesed={this.state.slovarBesed}
                        jezik={this.state.jezik}
                      />
                    }
                  />
                  <Route
                    path="/dostopnost"
                    element={
                      <AccessibilityPage slovarBesed={this.state.slovarBesed} />
                    }
                  />
                  <Route element={<Page404 />} />
                </Routes>
                <div>
                  <PWAPrompt
                    copyTitle="Namesti aplikacijo"
                    copyBody=""
                    copyShareButtonLabel="Tapnite 'Gumb za deljenje'"
                    copyAddHomeButtonLabel="Tapnite 'Dodaj na začetni zaslon (Add to home screen)'"
                  />
                  <Footer slovarBesed={this.state.slovarBesed} />
                </div>
              </BrowserRouter>
            </div>
          </Col>
        </div>
      </React.StrictMode>
    );
  }
}
