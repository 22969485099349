
import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import YouTube from 'react-youtube';

import { fetchGET } from '../../apiHelper';

export default class ManualPage extends Component
{
    render() {
        const { slovarBesed } = this.props;

        return (
            <>
            <Container>
                <Row>
                    <Col>
                        <h3 id="MNL-1" style={{marginTop: "20px", marginBottom: "20px"}}><i className="fa fa-info-circle" style={{paddingRight: "10px"}}></i>{slovarBesed["MNL-1"]?.naziv}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div id="MNL-2" className="pull-left menu-button"><i className="fa fa-cogs fa-2x"></i><div>{slovarBesed["MNL-2"]?.naziv}</div></div>
                        <div id="MNL-3">{slovarBesed["MNL-3"]?.naziv}</div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <div id="MNL-4" className="pull-left menu-button"><i className="fa fa-calendar fa-2x"></i><div>{slovarBesed["MNL-4"]?.naziv}</div></div>
                        <div id="MNL-5">{slovarBesed["MNL-5"]?.naziv}</div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <div id="MNL-6" className="pull-left menu-button"><i className="fa fa-database fa-2x"></i><div>{slovarBesed["MNL-6"]?.naziv}</div></div>
                        <div id="MNL-7">{slovarBesed["MNL-7"]?.naziv} <a id="MNL-8" href="https://it.um.si/studenti/Strani/default.aspx#id42" target="_blank" rel="noopener noreferrer">{slovarBesed["MNL-8"]?.naziv}</a></div>
                    </Col>
                </Row>
                <br/><br/><br/>
            </Container>
            </>
        )
    }
}