import React, { Component } from "react";
import LoadingAnimation from "../LoadingAnimation";

import { fetchGET } from "../../apiHelper";
import { NavLink } from "react-router-dom";

import { Modal, Row, Col, Container } from "react-bootstrap";

import Moment from "react-moment";

export default class NotificationPage extends Component {
  state = {
    loading: true,
    dnevnaObvestila: [],
    tedenskaObvestila: [],
    showModalWithDetails: false,
    selectedEventId: null,
    selectedEventDetails: {},
  };

  componentDidMount() {
    this.getTodayEvents();
    this.getEventsForFiveDays();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if 'myValue' has changed
    if (this.state.showModalWithDetails !== prevState.showModalWithDetails) {
      if (this.state.showModalWithDetails === true) {
        this.getEventDetails();
      }
    }
  }

  /*Pridobimo podrobnosti za POUK dogodke */
  getEventDetails = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` + `/api/sifrant/umdogodki`
      );
      if (response !== 204) {
        const event = response.find(
          (item) => item.pkTerminiID === this.state.selectedEventId
        );
        this.setState({ selectedEventDetails: event });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* Pridobimo današnje dogodke */
  getTodayEvents = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` + `/api/student/dnevna_obvestila`
      );
      if (response !== 204) {
        this.setState({ dnevnaObvestila: response });
      }
      this.setState({ loading: false });
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  //Pridobivanje obvestil za naslednjih 5 dni (od jutri naprej)
  getEventsForFiveDays = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` + `/api/student/tedenska_obvestila`
      );
      if (response !== 204) {
        this.setState({ tedenskaObvestila: response });
      }
      this.setState({ loading: false });
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  //helper funkcija za izbiro barvne palete in ikone glede na tip dogodka
  izbranaBarvaInIkonaZaTipDogodka = (tipDogodka) => {
    if (tipDogodka === 100) return ["#9c27b0", "fa fa-pencil-square-o"];
    if (tipDogodka === 101) return ["#bf0000", "fa fa-eur"];
    if (tipDogodka === 103) return ["#bf0000", "fa fa-graduation-cap"];
    if (tipDogodka === 104) return ["#bf0000", "fa fa-graduation-cap"];
    if (tipDogodka === 106) return ["#850063", "fa fa-users"];
  };

  //helper funkcija za odstranjevanje sekund iz ure
  formatTime = (ura) => {
    return ura.replace(/:\d{2}$/, "");
  };

  //helper fukcinja za kreiranje izpisa podrobnosti posameznega dogodka
  prikazPodrobnosti = (podatkiDogodka, slovarBesed) => {
    let tipDogodka;
    if (podatkiDogodka.tipDogodka === 100) {
      tipDogodka = slovarBesed["NTF-7"]?.naziv;
    } else if (podatkiDogodka.tipDogodka === 101) {
      tipDogodka = slovarBesed["NTF-8"]?.naziv;
    } else if (podatkiDogodka.tipDogodka === 103) {
      tipDogodka = slovarBesed["NTF-9"]?.naziv;
    } else if (podatkiDogodka.tipDogodka === 104) {
      tipDogodka = slovarBesed["NTF-10"]?.naziv;
    } else if (podatkiDogodka.tipDogodka === 106) {
      tipDogodka = slovarBesed["NTF-11"]?.naziv;
    }

    if (podatkiDogodka.datum !== "") {
      const datumZacetek = new Date(podatkiDogodka.datum).toLocaleDateString(
        "sl-SI",
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      );

      let ura;
      if (podatkiDogodka.ura_konec !== "") {
        ura = `, ${this.formatTime(podatkiDogodka.ura)} - ${this.formatTime(
          podatkiDogodka.ura_konec
        )}`;
      } else if (podatkiDogodka.ura !== "" && podatkiDogodka.ura_konec === "") {
        ura = `, ${this.formatTime(podatkiDogodka.ura)}`;
      }

      const prostor =
        podatkiDogodka.prostor !== "" && `, ${podatkiDogodka.prostor}`;

      //Kreiran string za prikaz podrobnosti dogodka
      const prikaziPodrobnostiString = (
        <p style={{ marginBottom: "0px" }}>
          {tipDogodka}
          {`, ${datumZacetek}`}
          {ura}
          {prostor}
        </p>
      );
      return prikaziPodrobnostiString;
    }
  };

  render() {
    const { slovarBesed } = this.props;
    return (
      <>
        {this.state.loading === true ? (
          <LoadingAnimation />
        ) : (
          <>
            <div>
              <Modal
                centered
                show={this.state.showModalWithDetails}
                onHide={() => this.setState({ showModalWithDetails: false })}
                animation={true}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-sm NTF-12 ">
                    {" "}
                    {slovarBesed["NTF-12"]?.naziv}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body-content-details ">
                  <div>
                    <Container fluid>
                      <Row>
                        <Col sm={4} id="NTF-13">
                          {slovarBesed["NTF-13"]?.naziv}
                        </Col>
                        <Col sm={8}>
                          <span className="font-weight-bold">
                            {this.state.selectedEventDetails.naslov}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4} id="NTF-14">
                          {slovarBesed["NTF-14"]?.naziv}
                        </Col>
                        <Col sm={8} className="font-weight-bold">
                          {this.state.selectedEventDetails.izvajalec}
                        </Col>
                      </Row>

                      {this.state.selectedEventDetails.prostor !== null && (
                        <Row>
                          <Col sm={4} id="NTF-15">
                            {slovarBesed["NTF-15"]?.naziv}
                          </Col>
                          <Col sm={8} className="font-weight-bold">
                            {this.state.selectedEventDetails.prostor}
                          </Col>
                        </Row>
                      )}

                      <>
                        <Row>
                          <Col id="NTF-16" sm={4}>
                            {slovarBesed["NTF-16"]?.naziv}
                          </Col>
                          <Col sm={8} className="font-weight-bold">
                            {this.state.selectedEventDetails?.ura?.replace(
                              /:\d{2}$/,
                              ""
                            )}
                            {" - "}
                            {this.state.selectedEventDetails?.ura_konec?.replace(
                              /:\d{2}$/,
                              ""
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col id="NTF-17" sm={4}>
                            {slovarBesed["NTF-17"]?.naziv}
                          </Col>
                          <Col sm={8} className="font-weight-bold">
                            {new Date(
                              this.state.selectedEventDetails.datum
                            ).toLocaleDateString("sl-SI", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                          </Col>
                        </Row>
                      </>
                    </Container>
                    {this.state.selectedEventDetails.link !== "" && (
                      <Container>
                        <Row className="mt-3">
                          <Col sm={12} id="NTF-18" className="text-secondary ">
                            {slovarBesed["NTF-18"]?.naziv}{" "}
                            <span>
                              <a
                                href={this.state.selectedEventDetails.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                id="NTF-19"
                              >
                                {slovarBesed["NTF-19"]?.naziv}{" "}
                              </a>
                            </span>
                          </Col>
                        </Row>
                      </Container>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            </div>

            <div>
              <div style={{ marginBottom: "20", padding: "8px" }}>
                <h3 id="NTF-1" style={{ margin: "10px 0 0 20px" }}>
                  <i className="fa fa-bell" style={{ marginRight: "10px" }}></i>
                  {slovarBesed["NTF-1"]?.naziv}
                </h3>
              </div>
            </div>
            <div
              style={{
                padding: "25px",
                paddingBottom: "0px",
                fontWeight: "bold",
              }}
            >
              <span>{slovarBesed["NTF-6"]?.naziv}</span>
            </div>
            {this.state.dnevnaObvestila.length === 0 && (
              <div style={{ padding: "30px" }}>
                <div id="NTF-2">{slovarBesed["NTF-2"]?.naziv}</div>
              </div>
            )}
            {this.state.dnevnaObvestila.length > 0 && (
              <div
                style={{
                  marginBottom: "20",
                  padding: "25px",
                  paddingTop: "0px",
                }}
              >
                {this.state.dnevnaObvestila.map((value, index) => {
                  return (
                    <div
                      className="card"
                      key={index}
                      style={{
                        marginTop: "20px",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="d-flex">
                        <div
                          style={{
                            backgroundColor:
                              this.izbranaBarvaInIkonaZaTipDogodka(
                                value.tipDogodka
                              )[0],
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                          className="col d-flex align-items-center justify-content-center col-2"
                        >
                          <i
                            className={`${
                              this.izbranaBarvaInIkonaZaTipDogodka(
                                value.tipDogodka
                              )[1]
                            }`}
                            style={{ fontSize: "25px", color: "white" }}
                          ></i>
                        </div>
                        <div className="col-10 p-2">
                          <h5>{value.naslov}</h5>
                          {this.prikazPodrobnosti(value, slovarBesed)}
                          <div
                            className="notification-eventDetails-button"
                            style={{
                              marginTop: "5px",
                              cursor: "pointer",
                              color: "#006a8e",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              this.setState({
                                showModalWithDetails: true,
                                selectedEventId: value.id,
                              });
                            }}
                          >
                            Podrobnosti dogodka
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              style={{
                padding: "25px",
                paddingBottom: "0px",
                fontWeight: "bold",
              }}
            >
              <span>{slovarBesed["NTF-5"]?.naziv}</span>
            </div>
            {this.state.tedenskaObvestila.length === 0 && (
              <div style={{ padding: "30px" }}>
                <div className="NTF-4">{slovarBesed["NTF-4"]?.naziv}</div>
              </div>
            )}
            {this.state.tedenskaObvestila.length > 0 && (
              <div
                style={{
                  padding: "25px",
                  marginBottom: "40px",
                  paddingTop: "0px",
                }}
              >
                {this.state.tedenskaObvestila.map((value, index) => {
                  return (
                    <div
                      className="card"
                      key={index}
                      style={{
                        marginTop: "20px",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="d-flex">
                        <div
                          style={{
                            backgroundColor:
                              this.izbranaBarvaInIkonaZaTipDogodka(
                                value.tipDogodka
                              )[0],
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                          className="col d-flex align-items-center justify-content-center col-2"
                        >
                          <i
                            className={`${
                              this.izbranaBarvaInIkonaZaTipDogodka(
                                value.tipDogodka
                              )[1]
                            }`}
                            style={{ fontSize: "25px", color: "white" }}
                          ></i>
                        </div>
                        <div className="col-10 p-2">
                          <h5>{value.naslov}</h5>
                          {this.prikazPodrobnosti(value, slovarBesed)}
                          {value.tipDogodka === 106 && (
                            <div
                              className="notification-eventDetails-button"
                              style={{
                                marginTop: "5px",
                                cursor: "pointer",
                                color: "#006a8e",
                                textDecoration: "underline",
                              }}
                              onClick={() => {
                                this.setState({
                                  showModalWithDetails: true,
                                  selectedEventId: value.id,
                                });
                              }}
                            >
                              Podrobnosti dogodka
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
