import React from 'react'
import { Tabs, Tab } from 'react-bootstrap';

import FormScheduleSelect from './form-components/FormScheduleSelect'
import FormUcnaEnotaSelect from './form-components/FormUcnaEnotaSelect'

const FormDashboard = ({programi, ucneEnote, slovarBesed}) => {
    return (
        <div>

            <Tabs className="tabs-selector" defaultActiveKey="urnik" id="uncontrolled-tab-example">
                
                <Tab id="FDB-3" eventKey="urnik" title={slovarBesed["FDB-3"]?.naziv}>
                {
                    programi.length < 3 ?
                    
                    <FormScheduleSelect slovarBesed={slovarBesed} /> :

                    <div id="FDB-1" style={{margin: "20px 20px 40px", fontSize: "1.2em"}}>{slovarBesed["FDB-1"]?.naziv}</div>
                }
                </Tab>
                
                <Tab id="FDB-4" eventKey="ucneEnote" title={slovarBesed["FDB-4"]?.naziv}>
                {
                    ucneEnote.length < 10 ?
                    
                    <FormUcnaEnotaSelect slovarBesed={slovarBesed} /> :

                    <div id="FDB-2" style={{margin: "20px 20px 40px", fontSize: "1.2em"}}>{slovarBesed["FDB-2"]?.naziv}</div>
                }
                </Tab>

            </Tabs>

        </div>
    )
}

export default FormDashboard;
