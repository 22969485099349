import React from 'react'
import { Card, Form, Button } from 'react-bootstrap';
import Select from 'react-select'
import { Context } from './../../SettingsPage'

const FormUcnaEnotaSelect = ({slovarBesed}) => {

    let {
        dropDownValues,
        dropDownSelectedValue,
        toggleButtons,
        postData,
        formHandlers
    } = React.useContext(Context)
    return (
        <div>
            <Card className="m-4 mrg0">
                <Card.Body>
                    <Form onSubmit={postData.postUcnaEnota}>
                        <Form.Group>
                            <Form.Label id="FUE-1">{slovarBesed["FUE-1"]?.naziv}</Form.Label>
                            < Select options={dropDownValues.faculties} isSearchable={false} onChange={formHandlers.handleChangeDropDownFaculties} value={dropDownSelectedValue.selectedFacultie} />
                        </Form.Group>
                        {!toggleButtons.hiddeOnLoadingUcnaEnotaAndButton && <>
                            <Form.Group>
                                <Form.Label id="FUE-2">{slovarBesed["FUE-2"]?.naziv}</Form.Label>
                                <Select id="FUE-3" placeholder={<div>{slovarBesed["FUE-3"]?.naziv}</div>} isSearchable={false} options={dropDownValues.ucneEnote} onChange={formHandlers.handleChangeDropDownUcnaEnota}  />
                            </Form.Group>
                            <Button id="FUE-4" className='mt-3' type="submit">{slovarBesed["FUE-4"]?.naziv}</Button>
                        </>
                        }
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default FormUcnaEnotaSelect;
