
import React from 'react'

const MessageNiUrnika = ({slovarBesed}) => {
    return (
        <div className="no-schedule-block" style={{background: "#dc3545", color: "white"}}>
            <h6 id="MNU-1">{slovarBesed["MNU-1"]?.naziv}</h6>
        </div>
    )
}

export default MessageNiUrnika
