import React from 'react'
import { Button, Col } from 'react-bootstrap';

const AddButton = ({onClickHandler, slovarBesed}) => {
    return (
         
        <Col className="justify-content-center d-flex" style={{marginTop: "40px"}}>
            <Button id="ADB-1" onClick={onClickHandler} className="w-75 p-2">
                <i className="fa fa-plus" aria-hidden="true"></i> {slovarBesed["ADB-1"]?.naziv}
            </Button>
        </Col>
      
    )
}

export default AddButton
