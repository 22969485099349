import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "./index.css";

/*MSAL import */
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { msalConfig, extraQuerys } from "./msalAuthConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={extraQuerys}
    >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </MsalAuthenticationTemplate>
  </MsalProvider>
);

serviceWorkerRegistration.register();
