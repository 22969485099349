import { acquireAPItoken } from "./msalAuthConfig";

async function getTokenForAPI() {
  const bearerToken = await acquireAPItoken();
  return bearerToken;
}

export const fetchGET = async (url) => {
  const bearerToken = await getTokenForAPI();

  const settings = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
  };

  try {
    const response = await fetch(url, settings);
    const contentType = response.headers.get("content-type");
    if (!response.ok) {
      throw new Error("Network response was not OK");
    }
    if (response.status === 204) {
      return response.status;
    }
    if (contentType && contentType.includes("application/json")) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchPOST = async (url, data) => {
  const bearerToken = await getTokenForAPI();

  const setting = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, setting);
    const contentType = response.headers.get("content-type");

    if (!response.ok) {
      throw new Error("Failed to post data");
    }
    if (contentType && contentType.includes("application/json")) {
      const responseData = await response.json();
      return responseData;
    } else {
      return response.status;
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
