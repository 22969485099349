import React from "react";
import { useEffect, useState } from "react";
import Header from "../Header";

/*React bootstrap components import*/
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { fetchGET } from "../../apiHelper";

export default function AccessibilityPage({ slovarBesed }) {
  const [datum, setDatum] = useState(null);

  useEffect(() => {
    const fetchGETData = async () => {
      try {
        const datumPregleda = await fetchGET(
          `${process.env.REACT_APP_ApiDomain}` + `/api/admin/datum_dostopnost`
        );
        const datumFormat = new Date(datumPregleda).toLocaleDateString("sl-SI");
        setDatum(datumFormat);
      } catch (error) {
        console.log(error);
      }
    };
    fetchGETData();
  }, []);

  return (
    <>
      <div className="p-4 text-justify">
        <Row className="mb-4">
          <Col>
            <span id="ACS-2">{slovarBesed["ACS-2"]?.naziv}</span>
            <span id="ACS-3">
              <a href="http://www.pisrs.si/Pis.web/pregledPredpisa?id=ZAKO7718">
                {" "}
                {slovarBesed["ACS-3"]?.naziv}
              </a>
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 id="ACS-4">{slovarBesed["ACS-4"]?.naziv}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-justify">
              <span id="ACS-5">{slovarBesed["ACS-5"]?.naziv} </span>
              <span id="ACS-6"> {slovarBesed["ACS-6"]?.naziv}</span>
            </p>
            <ul>
              <li id="ASC-7">{slovarBesed["ACS-7"]?.naziv}</li>
              <li id="ASC-8">{slovarBesed["ACS-8"]?.naziv}</li>
              <li id="ASC-9">{slovarBesed["ACS-9"]?.naziv}</li>
              <li id="ASC-10">{slovarBesed["ACS-10"]?.naziv}</li>
              <li id="ASC-11">{slovarBesed["ACS-11"]?.naziv}</li>
              <li id="ASC-12">{slovarBesed["ACS-12"]?.naziv}</li>
              <li id="ASC-13">{slovarBesed["ACS-13"]?.naziv}</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 id="ASC-14">{slovarBesed["ACS-14"]?.naziv}</h3>
          </Col>
        </Row>
        <Row>
          <Col id="ASC-15">{slovarBesed["ACS-15"]?.naziv}</Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h3 id="ASC-16">{slovarBesed["ACS-16"]?.naziv}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p id="ASC-17">{slovarBesed["ACS-17"]?.naziv}</p>
            <span id="ASC-18">{slovarBesed["ACS-18"]?.naziv}</span>
            <ul>
              <li id="ASC-18">{slovarBesed["ACS-19"]?.naziv}</li>
              <li id="ASC-19">{slovarBesed["ACS-20"]?.naziv}</li>
              <li id="ASC-20">{slovarBesed["ACS-21"]?.naziv}</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 id="ASC-22">{slovarBesed["ACS-22"]?.naziv}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p id="ASC-23">{slovarBesed["ACS-23"]?.naziv}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 id="ASC-24">{slovarBesed["ACS-24"]?.naziv} </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <span id="ASC-25">{slovarBesed["ACS-25"]?.naziv}</span> <br />
            <span id="ASC-26">{slovarBesed["ACS-26"]?.naziv}</span> <br />
            <span id="ASC-27">{slovarBesed["ACS-27"]?.naziv}</span> <br />
            <span id="ASC-28">
              <a href={`mailto:${slovarBesed["ACS-28"]?.naziv}`}>
                {slovarBesed["ACS-28"]?.naziv}
              </a>
            </span>{" "}
            <br />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <p id="ASC-29">{slovarBesed["ACS-29"]?.naziv}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <span id="ASC-30">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.gov.si/drzavni-organi/organi-v-sestavi/inspektorat-za-informacijsko-druzbo/"
              >
                {slovarBesed["ACS-30"]?.naziv}
              </a>
            </span>{" "}
            <br />
            <span id="ASC-31">{slovarBesed["ACS-31"]?.naziv}</span> <br />
            <span id="ASC-32">{slovarBesed["ACS-32"]?.naziv}</span> <br />
            <span id="ASC-33">{slovarBesed["ACS-33"]?.naziv}</span> <br />
            <span id="ASC-34">
              <a href={`mailto:${slovarBesed["ACS-34"]?.naziv}`}>
                {slovarBesed["ACS-34"]?.naziv}
              </a>
            </span>{" "}
            <br />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h3 id="ASC-35">{slovarBesed["ACS-35"]?.naziv}</h3>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <span id="ASC-36">{slovarBesed["ACS-36"]?.naziv}</span> <br />
            <span id="ASC-37">{slovarBesed["ASC-37"]?.naziv}</span>
            <span>{` ${datum}.`}</span> <br />
          </Col>
        </Row>
      </div>
    </>
  );
}
