import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import LoadingAnimation from "../LoadingAnimation";
import Switch from "react-switch";

//Component import
import MessageNoScheduleData from "./SettingsPage-components/MessageNoScheduleData";
import MessageNiUrnika from "./SettingsPage-components/MessageNiUrnika";
import FormDashboard from "./SettingsPage-components/FormDashboard";
import UcneEnoteList from "./SettingsPage-components/UcneEnoteList";
import ScheduleList from "./SettingsPage-components/ScheduleList";
import AddButton from "./SettingsPage-components/AddButton";

import { fetchGET, fetchPOST } from "../../apiHelper";

export let Context = React.createContext();

export default class SettingsPage extends Component {
  state = {
    userDefaultFaculty: "",
    userSavedSchedules: [],
    faculties: [],
    selectedOptionFacultie: {},
    programs: [],
    selectedProgram: "",
    programYears: [],
    selectedProgramYear: "",
    courses: [],
    selectedCourse: "",
    ucneEnote: [],
    selectedUcnaEnota: "",
    userSavedUcneEnote: [],
    userOpcijeSkupine: [],
    userOpcijeSkupine1: [],
    userSkupine: [],
    selectedSkupine: [],
    hiddeDropdowns: false,
    hiddeMessageNoSchedule: true,
    hiddeMessageNiUrnika: true,
    linkNoSchedule: "",
    hiddeOnLoadingProgram: true,
    hiddeOnLoadingYears: true,
    hiddeOnLoadingCourseAndButton: true,
    hiddeOnLoadingUcnaEnotaAndButton: true,
    showAddButon: false,
    showForm: true,
    showFormCloseButton: true,
    loading: true,
    showDetails: true,
    radioButton: [],
  };

  async componentDidMount() {
    this.getUserInfo(); //pridobi podatke o default fakulteti študenta(tam kjer je vpisan)
    this.getNastavitveAplikacije(); //Pridobi podatke za nastavitve.
    this.checkSavedUserSchedules(); //prikaz shranjenih urnikov
    this.checkSavedUserUcnaEnota(); //prikaz shranjenih ucnih enot
  }

  handleChangeDropDownFaculties = (selected) => {
    if (selected.active === true) {
      if (selected.active1 === true) {
        this.setState(
          {
            hiddeOnLoadingProgram: true,
            hiddeOnLoadingYears: true,
            hiddeOnLoadingUcnaEnotaAndButton: true,
            hiddeOnLoadingCourseAndButton: true,
            hiddeMessageNiUrnika: true,
            hiddeMessageNoSchedule: true,
            selectedSkupine: [],
            selectedOptionFacultie: selected,
          },
          () => {
            this.getProgramsInfo();
            this.getUcnaEnotaInfo();
          }
        );
      } else {
        this.setState({
          hiddeDropdowns: true,
          hiddeMessageNiUrnika: false,
          hiddeMessageNoSchedule: true,
          selectedOptionFacultie: selected,
          hiddeOnLoadingUcnaEnotaAndButton: true,
        });
      }
    } else {
      this.setState({
        linkNoSchedule: selected.link,
        hiddeDropdowns: true,
        hiddeMessageNiUrnika: true,
        hiddeMessageNoSchedule: false,
        selectedOptionFacultie: selected,
        hiddeOnLoadingUcnaEnotaAndButton: true,
      });
    }
  };

  handleChangeDropDownPrograms = (selected) => {
    this.setState(
      {
        selectedProgram: selected,
        hiddeOnLoadingYears: true,
        hiddeOnLoadingCourseAndButton: true,
        selectedSkupine: [],
      },
      this.getYears
    );
  };

  handleChangeDropDownProgramsYears = (selected) => {
    this.setState(
      {
        selectedProgramYear: selected,
        hiddeOnLoadingCourseAndButton: true,
        selectedSkupine: [],
      },
      this.getCourses
    );
  };

  handleChangeDropDownCourse = (selected) => {
    this.setState(
      { selectedCourse: selected, selectedSkupine: [] },
      this.getOpcijeSkupine
    );
  };

  handleChangeDropDownUcnaEnota = (selected) => {
    this.setState({ selectedUcnaEnota: selected });
  };

  handleChangeDropDownSkupine = (selected) => {
    const skupineArray = [];
    selected.forEach((element) => {
      skupineArray.push({ id: element.value, naziv: element.label });
    });
    this.setState({ selectedSkupine: selected, userSkupine: skupineArray });
  };

  handleShowForm = () => {
    this.setState({ showForm: true, showDetails: true });
  };

  handleCloseForm = () => {
    this.setState({ showForm: false });
  };

  callbackGetProgramsInfoAndUcnaEnotaInfo = () => {
    this.getProgramsInfo();
    this.getUcnaEnotaInfo();
  };

  checkSavedUserSchedules = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` + `/api/student/studiji`
      );

      if (Array.isArray(response)) {
        this.setState({
          userSavedSchedules: response,
          showAddButon: true,
          showForm: false,
          showFormCloseButton: true,
        });
      } else {
        let showForm = true;
        let showButton,
          showCloseButton = false;
        if (this.state.userSavedUcneEnote.length !== 0) {
          showForm = false;
          showButton = true;
          showCloseButton = true;
        }
        this.setState({
          userSavedSchedules: [],
          showAddButon: showButton,
          showDetails: true,
          showForm: showForm,
          showFormCloseButton: showCloseButton,
        });
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  checkSavedUserUcnaEnota = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` + `/api/student/ucne_enote`
      );
      if (Array.isArray(response)) {
        this.setState({
          userSavedUcneEnote: response,
          showAddButon: true,
          showForm: false,
          showFormCloseButton: true,
        });
      } else {
        let showForm = true;
        let showButton,
          showCloseButton = false;
        if (this.state.userSavedSchedules.length !== 0) {
          showForm = false;
          showButton = true;
          showCloseButton = true;
        }
        this.setState({
          userSavedUcneEnote: [],
          showAddButon: showButton,
          showForm: showForm,
          showDetails: true,
          showFormCloseButton: showCloseButton,
        });
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getUserInfo = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` + `/api/student/studiji_aips`
      );

      if (response.status === "No data!") {
        this.setState(
          {
            userDefaultFaculty: "",
          },
          this.getFacultiesInfo
        );
      } else {
        if (response[0].fakulteta === "FE" || response[0].fakulteta === "FOV") {
          this.setState(
            {
              userDefaultFaculty: "",
            },
            this.getFacultiesInfo
          );
        } else {
          this.setState(
            {
              userDefaultFaculty: response[0].fakulteta,
            },
            this.getFacultiesInfo
          );
        }
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getFacultiesInfo = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` + `/api/sifrant/fakultete`
      );
      const facultiesArray = [];

      response.forEach((item) => {
        facultiesArray.push({
          value: item.fakulteta,
          label: item.naziv,
          active: item.obstaja,
          link: item.link,
          active1: item.aktiven,
        });
      });

      let facultiSelected = [];

      if (this.state.userDefaultFaculty === "") {
        facultiSelected = facultiesArray;
      } else {
        facultiSelected = facultiesArray.filter(
          (item) => item.value === this.state.userDefaultFaculty
        );
      }

      if (!facultiSelected[0].active1) {
        this.setState({
          faculties: facultiesArray,
          selectedOptionFacultie: facultiSelected[0],
          hiddeDropdowns: true,
          hiddeMessageNiUrnika: false,
          loading: false,
        });
      } else {
        this.setState(
          {
            faculties: facultiesArray,
            selectedOptionFacultie: facultiSelected[0],
            loading: false,
          },
          () => {
            this.callbackGetProgramsInfoAndUcnaEnotaInfo();
          }
        );
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getUcnaEnotaInfo = async () => {
    try {
      if (this.state.selectedOptionFacultie.value !== undefined) {
        const response = await fetchGET(
          `${process.env.REACT_APP_ApiDomain}` +
            `/api/sifrant/ucne_enote?fakulteta=${this.state.selectedOptionFacultie.value}`
        );
        if (Array.isArray(response) === true) {
          if (response.length !== 0) {
            const ucnaEnotaArray = [];
            response.forEach((element) => {
              ucnaEnotaArray.push({ value: element.id, label: element.course });
            });
            this.setState({
              ucneEnote: ucnaEnotaArray,
              selectedUcnaEnota: ucnaEnotaArray[0],
              hiddeMessageNoSchedule: true,
              hiddeOnLoadingUcnaEnotaAndButton: false,
              loading: false,
            });
          }
        }
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getProgramsInfo = async () => {
    const p1 = `fakulteta=` + `${this.state.selectedOptionFacultie.value}`;

    try {
      if (this.state.selectedOptionFacultie.value !== undefined) {
        const response = await fetchGET(
          `${process.env.REACT_APP_ApiDomain}` +
            `/api/sifrant/programi` +
            `?${p1}`
        );

        const programsArray = [];

        response.forEach((item) => {
          programsArray.push({
            value: {
              id: item.id,
              years: item.years,
            },
            label: item.label,
          });
        });

        this.setState(
          {
            programs: programsArray,
            selectedProgram: programsArray[0],
            hiddeDropdowns: false,
            hiddeMessageNoSchedule: true,
            hiddeOnLoadingProgram: false,
            loading: false,
          },
          this.getYears
        );
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getYears = () => {
    let programYears = this.state.selectedProgram.value.years;
    let programYearsNumbers = [];

    for (let num = 1; num <= programYears; num++) {
      programYearsNumbers.push({
        value: num,
        label: num,
      });
    }

    this.setState(
      {
        programYears: programYearsNumbers,
        selectedProgramYear: programYearsNumbers[0],
        hiddeOnLoadingYears: false,
      },
      this.getCourses
    );
  };

  getCourses = async () => {
    const p1 = `fakulteta=` + `${this.state.selectedOptionFacultie.value}`;
    const p2 = `program=` + `${this.state.selectedProgram.value.id}`;
    const p3 = `letnik=` + `${this.state.selectedProgramYear.value}`;

    try {
      if (p1 !== undefined && p2 !== undefined && p3 !== undefined) {
        const response = await fetchGET(
          `${process.env.REACT_APP_ApiDomain}` +
            `/api/sifrant/smeri` +
            `?${p1}&${p2}&${p3}`
        );
        const coursesArray = [];

        response.forEach((item) => {
          coursesArray.push({
            value: item.id,
            label: item.label,
          });
        });

        this.setState(
          {
            courses: coursesArray,
            selectedCourse: coursesArray[0],
            hiddeOnLoadingCourseAndButton: false,
          },
          this.getNastavitveAplikacije
        );
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getOpcijeSkupine = async () => {
    try {
      if (
        this.state.selectedOptionFacultie.value !== undefined &&
        this.state.selectedCourse.value !== undefined
      ) {
        const response = await fetchGET(
          `${process.env.REACT_APP_ApiDomain}` +
            `/api/sifrant/skupine?fakulteta=${this.state.selectedOptionFacultie.value}&smer=${this.state.selectedCourse.value}`
        );
        const skupineArray = [];
        const skupineArray1 = [];
        response.forEach((element) => {
          skupineArray.push({ value: element.id, label: element.name });
          skupineArray1.push({ id: element.id, naziv: element.name });
        });
        this.setState({
          userOpcijeSkupine: skupineArray,
          userOpcijeSkupine1: skupineArray1,
        });
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getNastavitveAplikacije = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/nastavitve_aplikacije`
      );

      if (response.status !== "No data!") {
        let nastavitveArray;
        let dostopnost;
        let niUrnika;

        /* Privzeti pogled (dnevni, tedenski, mesečni) nastavitev za prikaz na koledarju */
        response.forEach((item) => {
          if (item.privzetPogled === 1) nastavitveArray = [true, false, false];
          if (item.privzetPogled === 2) nastavitveArray = [false, true, false];
          if (item.privzetPogled === 3) nastavitveArray = [false, false, true];

          dostopnost = item.dostopnost;
          niUrnika = item.niUrnika;
        });

        this.setState(
          {
            radioButton: nastavitveArray,
            dostopnost: dostopnost,
            niUrnika: niUrnika,
            nastavitevPrazniki: response[0].prazniki,
            nastavitevPrijavnice: response[0].prijavnice,
            nastavitevZapadlosti: response[0].zapadlosti,
            nastavitevDogodki: response[0].uMdogodki,
          },
          () => {
            this.getOpcijeSkupine();
          }
        );
      } else {
        this.setInitialPogled();
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  setInitialPogled = async () => {
    try {
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` + `/api/student/zacetni_pogled`
      );
      this.setState(
        { radioButton: [true, false, false] },
        this.getOpcijeSkupine
      );
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  setPogled = async (num) => {
    let nastavitveArray;
    if (num === 1) nastavitveArray = [true, false, false];
    if (num === 2) nastavitveArray = [false, true, false];
    if (num === 3) nastavitveArray = [false, false, true];

    this.setState({
      radioButton: nastavitveArray,
      loading: true,
    });

    try {
      const p1 = `pogled=` + `${num}`;
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/shrani_pogled` +
          `?${p1}`
      );
      this.setState({
        loading: false,
      });

      if (response !== 200) alert("VNOS NI BIL USPEŠEN!");
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  setPogled1 = async () => {
    let state;

    if (this.state.dostopnost === true) {
      state = false;
      this.setState({ dostopnost: false });
      document.getElementById("dostopnost").style.display = "none";
      document.getElementById("dostopnostEng").style.display = "none";
    } else {
      const { jezik } = this.props;
      state = true;
      this.setState({ dostopnost: true });
      if (jezik === "slo") {
        document.getElementById("dostopnost").style.display = "block";
        document.getElementById("dostopnostEng").style.display = "none";
      } else {
        document.getElementById("dostopnost").style.display = "none";
        document.getElementById("dostopnostEng").style.display = "block";
      }
    }

    try {
      const p1 = `state=` + state;
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/shrani_pogled1` +
          `?${p1}`
      );
      this.setState({
        loading: false,
      });

      if (response !== 200) alert("VNOS NI BIL USPEŠEN!");
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  setPogled3 = async () => {
    let state;

    if (this.state.nastavitevPrazniki === true) {
      state = false;
      this.setState({ nastavitevPrazniki: false });
    } else {
      state = true;
      this.setState({ nastavitevPrazniki: true });
    }
    try {
      const p1 = `state=` + state;
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/shrani_pogled3` +
          `?${p1}`
      );
      if (response !== 200) alert("VNOS NI BIL USPEŠEN!");
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  setPogled4 = async () => {
    let state;

    if (this.state.nastavitevPrijavnice === true) {
      state = false;
      this.setState({ nastavitevPrijavnice: false });
    } else {
      state = true;
      this.setState({ nastavitevPrijavnice: true });
    }

    try {
      const p1 = `state=` + state;
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/shrani_pogled4` +
          `?${p1}`
      );
      if (response !== 200) alert("VNOS NI BIL USPEŠEN!");
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  setPogled5 = async () => {
    let state;

    if (this.state.nastavitevZapadlosti === true) {
      state = false;
      this.setState({ nastavitevZapadlosti: false });
    } else {
      state = true;
      this.setState({ nastavitevZapadlosti: true });
    }
    try {
      const p1 = `state=` + state;
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/shrani_pogled5` +
          `?${p1}`
      );
      if (response !== 200) alert("VNOS NI BIL USPEŠEN!");
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  setPogled7 = async () => {
    let state;

    if (this.state.nastavitevDogodki === true) {
      state = false;
      this.setState({ nastavitevDogodki: false });
    } else {
      state = true;
      this.setState({ nastavitevDogodki: true });
    }

    try {
      const p1 = `state=` + state;
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/shrani_pogled7` +
          `?${p1}`
      );
      if (response !== 200) alert("VNOS NI BIL USPEŠEN!");
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  toggleNiUrnika = async () => {
    let state;

    if (this.state.niUrnika === true) {
      state = false;
    } else {
      state = true;
    }

    this.setState({
      niUrnika: state,
    });

    try {
      const p1 = `state=` + `${state}`;
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/shrani_pogled2` +
          `?${p1}`
      );
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  postSelectedScheduleData = async (e) => {
    e.preventDefault();

    let tmp;
    let vseSkupine;

    if (this.state.userSkupine.length > 0) {
      tmp = this.state.userSkupine;
      vseSkupine = false;
    } else {
      tmp = this.state.userOpcijeSkupine1;
      vseSkupine = true;
    }

    if (
      this.state.selectedCourse.value === "" ||
      this.state.selectedProgram.label === "" ||
      this.state.selectedProgram.value.id === "" ||
      this.state.selectedProgramYear.value === "" ||
      this.state.selectedOptionFacultie.value === ""
    ) {
      alert("Niso izbrani vsi atributi!");
    } else {
      this.setState({
        selectedSkupine: [],
        userSkupine: [],
        loading: true,
      });

      const body = {
        fakultetaID: this.state.selectedOptionFacultie.value,
        programID: this.state.selectedProgram.value.id,
        programNaziv: this.state.selectedProgram.label,
        smerID: this.state.selectedCourse.value,
        smerNaziv: this.state.selectedCourse.label,
        letnik: this.state.selectedProgramYear.value,
        vseSkupine: vseSkupine,
        skupine: tmp,
      };

      try {
        const response = await fetchPOST(
          `${process.env.REACT_APP_ApiDomain}` +
            `/api/student/shrani_nastavitve`,
          body
        );
        this.setState({ loading: false });

        if (response === 200) {
          this.checkSavedUserSchedules();
        } else {
          alert("VNOS NI BIL USPEŠEN!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  postSelectedUcnaEnotaData = async (e) => {
    e.preventDefault();

    if (
      this.state.selectedOptionFacultie.value === "" ||
      this.state.selectedUcnaEnota.value === "" ||
      this.state.selectedUcnaEnota.label === ""
    ) {
      alert("NISO BILI IZBRANI VSI ATRIBUTI!");
    } else {
      try {
        const body = {
          fakultetaID: this.state.selectedOptionFacultie.value,
          ucnaEnotaID: this.state.selectedUcnaEnota.value,
          ucnaEnotaNaziv: this.state.selectedUcnaEnota.label,
        };
        const response = await fetchPOST(
          `${process.env.REACT_APP_ApiDomain}` +
            `/api/student/shrani_nastavitve_ue`,
          body
        );
        if (response === 200) {
          this.checkSavedUserUcnaEnota();
        } else {
          alert("VNOS NI BIL USPEŠEN!");
        }
      } catch (error) {
        console.log("Prišlo je do napake");
      }
    }
  };

  deleteSchedule = async (schedules) => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/odstrani_urnik?id=${schedules.id}`
      );
      this.deleteGroups(schedules);
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  deleteGroups = async (schedules) => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/odstrani_skupine?id=${schedules.id}`
      );
      this.checkSavedUserSchedules();
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  deleteUcnaEnota = async (ucneEnote) => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/odstrani_ue?id=${ucneEnote.id}`
      );
      this.checkSavedUserUcnaEnota();
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  hideModalDetails = () => {
    this.setState({ showDetails: false, showAddButon: true });
  };

  showModalDetails = () => {
    this.setState({ showDetails: true });
  };

  showButton = () => {
    this.setState({ showAddButon: true });
  };

  render() {
    const { slovarBesed } = this.props;

    return (
      <>
        {/* LOADER */}

        {this.state.loading === true ? (
          <LoadingAnimation />
        ) : (
          <>
            <div id="settings-page">
              <h3 id="STN-1" style={{ marginTop: "80px", marginLeft: "25px" }}>
                <i className="fa fa-cogs" style={{ paddingRight: "10px" }}></i>
                {slovarBesed["STN-1"]?.naziv}
              </h3>

              <div className="setting-content">
                {/* ŠTUDIJSKI PROGRAMI */}

                <div>
                  {this.state.userSavedSchedules.length !== 0 && (
                    <h5
                      id="STN-2"
                      style={{
                        marginTop: "30px",
                        marginLeft: "24px",
                        color: "#006385",
                        marginBottom: "-10px",
                      }}
                    >
                      {slovarBesed["STN-2"]?.naziv}
                    </h5>
                  )}

                  <ScheduleList
                    userSchedules={this.state.userSavedSchedules}
                    deleteScheduleHandler={this.deleteSchedule}
                    slovarBesed={slovarBesed}
                  />
                </div>

                {/* UČNE ENOTE */}

                <div>
                  {this.state.userSavedUcneEnote.length !== 0 && (
                    <h5
                      id="STN-3"
                      style={{
                        marginTop: "40px",
                        marginLeft: "24px",
                        color: "#006385",
                        marginBottom: "-10px",
                      }}
                    >
                      {slovarBesed["STN-3"]?.naziv}
                    </h5>
                  )}

                  <UcneEnoteList
                    userUcneEnote={this.state.userSavedUcneEnote}
                    deleteUcneEnoteHandler={this.deleteUcnaEnota}
                    slovarBesed={slovarBesed}
                  ></UcneEnoteList>
                </div>

                {/* DODAJ GUMB */}

                <div>
                  {this.state.showAddButon && (
                    <AddButton
                      onClickHandler={this.handleShowForm}
                      slovarBesed={slovarBesed}
                    />
                  )}
                </div>

                {/* NOV URNIK - MODAL */}

                {this.state.showForm && (
                  <div>
                    <Modal
                      centered
                      show={this.state.showDetails}
                      onHide={this.hideModalDetails}
                      animation={true}
                    >
                      <Modal.Header closeButton onClick={this.showButton}>
                        <Modal.Title id="example-modal-sizes-title-sm STN-4">
                          {slovarBesed["STN-4"]?.naziv}
                        </Modal.Title>
                      </Modal.Header>

                      <Modal.Body className="modal-body-content-details">
                        <Context.Provider
                          value={{
                            dropDownValues: {
                              faculties: this.state.faculties,

                              programs: this.state.programs,

                              programYears: this.state.programYears,

                              courses: this.state.courses,

                              skupine: this.state.userOpcijeSkupine,

                              ucneEnote: this.state.ucneEnote,
                            },

                            dropDownSelectedValue: {
                              selectedFacultie:
                                this.state.selectedOptionFacultie,

                              selectedProgramYear:
                                this.state.selectedProgramYear,

                              selectedCourse: this.state.selectedCourse,

                              selectedProgram: this.state.selectedProgram,

                              selectedSkupine: this.state.selectedSkupine,

                              selectedUcnaEnota: this.state.selectedUcnaEnota,
                            },

                            formHandlers: {
                              handleChangeDropDownFaculties:
                                this.handleChangeDropDownFaculties,

                              handleChangeDropDownPrograms:
                                this.handleChangeDropDownPrograms,

                              handleChangeDropDownProgramYear:
                                this.handleChangeDropDownProgramsYears,

                              handleChangeDropDownCourse:
                                this.handleChangeDropDownCourse,

                              handleChangeDropDownSkupine:
                                this.handleChangeDropDownSkupine,

                              handleChangeDropDownUcnaEnota:
                                this.handleChangeDropDownUcnaEnota,

                              handleCloseForm: this.handleCloseForm,
                            },

                            postData: {
                              postSchedule: this.postSelectedScheduleData,

                              postUcnaEnota: this.postSelectedUcnaEnotaData,
                            },

                            toggleButtons: {
                              hiddeDropdowns: this.state.hiddeDropdowns,

                              showFormCloseButton:
                                this.state.showFormCloseButton,

                              hiddeOnLoadingProgram:
                                this.state.hiddeOnLoadingProgram,

                              hiddeOnLoadingYears:
                                this.state.hiddeOnLoadingYears,

                              hiddeOnLoadingCourseAndButton:
                                this.state.hiddeOnLoadingCourseAndButton,

                              hiddeOnLoadingUcnaEnotaAndButton:
                                this.state.hiddeOnLoadingUcnaEnotaAndButton,
                            },
                          }}
                        >
                          <FormDashboard
                            programi={this.state.userSavedSchedules}
                            ucneEnote={this.state.userSavedUcneEnote}
                            slovarBesed={slovarBesed}
                          />

                          <div>
                            {!this.state.hiddeMessageNoSchedule && (
                              <MessageNoScheduleData
                                link={this.state.linkNoSchedule}
                                slovarBesed={slovarBesed}
                              />
                            )}
                          </div>

                          <div>
                            {!this.state.hiddeMessageNiUrnika && (
                              <MessageNiUrnika slovarBesed={slovarBesed} />
                            )}
                          </div>
                        </Context.Provider>
                      </Modal.Body>
                    </Modal>
                  </div>
                )}

                {/* NASTAVITVE */}

                <hr style={{ margin: "40px 0 35px" }} />

                <div id="nastavitve">
                  {/* Privzet pogled urnika */}

                  <h5
                    id="STN-5"
                    style={{ margin: "30px 0px 10px", color: "#006385" }}
                  >
                    {slovarBesed["STN-5"]?.naziv}
                  </h5>

                  <div>
                    <input
                      id="day"
                      type="radio"
                      value="1"
                      name="view"
                      checked={this.state.radioButton[0]}
                      onChange={(e) => {}}
                      onClick={() => this.setPogled(1)}
                      style={{ cursor: "pointer" }}
                    ></input>

                    <label
                      id="STN-6"
                      htmlFor="day"
                      style={{ cursor: "pointer" }}
                    >
                      {slovarBesed["STN-6"]?.naziv}
                    </label>
                  </div>

                  <div>
                    <input
                      id="week"
                      type="radio"
                      value="2"
                      name="view"
                      checked={this.state.radioButton[1]}
                      onChange={(e) => {}}
                      onClick={() => this.setPogled(2)}
                      style={{ cursor: "pointer" }}
                    ></input>

                    <label
                      id="STN-7"
                      htmlFor="week"
                      style={{ cursor: "pointer" }}
                    >
                      {slovarBesed["STN-7"]?.naziv}
                    </label>
                  </div>

                  <div>
                    <input
                      id="list"
                      type="radio"
                      value="3"
                      name="view"
                      checked={this.state.radioButton[2]}
                      onChange={(e) => {}}
                      onClick={() => this.setPogled(3)}
                      style={{ cursor: "pointer" }}
                    ></input>

                    <label
                      id="STN-8"
                      htmlFor="list"
                      style={{ cursor: "pointer" }}
                    >
                      {slovarBesed["STN-8"]?.naziv}
                    </label>
                  </div>

                  <hr style={{ margin: "25px -25px 35px" }} />

                  {/* Prikaz podatkov na urniku */}

                  <h5 id="STN-9" style={{ marginLeft: "0px" }}>
                    {slovarBesed["STN-9"]?.naziv}
                  </h5>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div id="STN-10">{slovarBesed["STN-10"]?.naziv}</div>

                    <Switch
                      onColor="#2f9e53"
                      offColor="#cf4242"
                      checked={this.state.nastavitevPrazniki}
                      onChange={() => this.setPogled3()}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div id="STN-11">{slovarBesed["STN-11"]?.naziv}</div>

                    <Switch
                      onColor="#2f9e53"
                      offColor="#cf4242"
                      checked={this.state.nastavitevPrijavnice}
                      onChange={() => this.setPogled4()}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div id="STN-12">{slovarBesed["STN-12"]?.naziv}</div>

                    <Switch
                      onColor="#2f9e53"
                      offColor="#cf4242"
                      checked={this.state.nastavitevZapadlosti}
                      onChange={() => this.setPogled5()}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div id="STN-19">{slovarBesed["STN-19"]?.naziv}</div>

                    <Switch
                      onColor="#2f9e53"
                      offColor="#cf4242"
                      checked={this.state.nastavitevDogodki}
                      onChange={() => this.setPogled7()}
                    />
                  </div>

                  <hr style={{ margin: "35px -25px" }} />

                  {/* splošne nastavitve */}

                  <h5 id="STN-14" style={{ marginLeft: "0px" }}>
                    {slovarBesed["STN-14"]?.naziv}
                  </h5>

                  {/* Prikaz obvestila "Ni izbranega študijskega programa za urnik" */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div id="STN-15" style={{ paddingRight: "15px" }}>
                      {slovarBesed["STN-15"]?.naziv}"
                    </div>

                    <Switch
                      onColor="#2f9e53"
                      offColor="#cf4242"
                      checked={this.state.niUrnika}
                      onChange={() => this.toggleNiUrnika()}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div id="STN-16">{slovarBesed["STN-16"]?.naziv}</div>

                    <Switch
                      onColor="#2f9e53"
                      offColor="#cf4242"
                      checked={this.state.dostopnost}
                      onChange={() => this.setPogled1()}
                    />
                  </div>

                  <hr style={{ margin: "35px -25px" }} />

                  <h5 id="STN-17" style={{ marginLeft: "0px" }}>
                    {slovarBesed["STN-17"]?.naziv}
                  </h5>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      marginBottom: "115px",
                    }}
                  >
                    <div id="STN-18">{slovarBesed["STN-18"]?.naziv}</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
