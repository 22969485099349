import React, { useEffect, useState } from "react";

import { Container, Row, Col } from "react-bootstrap";

import { fetchGET } from "../../apiHelper";

const EventsPage = ({ slovarBesed }) => {
  const [dogodki, setDogodki] = useState([]);

  useEffect(() => {
    const getDogodki = async () => {
      try {
        const response = await fetchGET(
          `${process.env.REACT_APP_ApiDomain}` + `/api/sifrant/umdogodki`
        );

        if (response !== 204) {
          setDogodki(response);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getDogodki();
  }, []);

  return (
    <Container className="container-marginBottom">
      <Row className="mb-3">
        <Col>
          <h3 id="ENV-1" style={{ marginTop: "20px", marginBottom: "20px" }}>
            <i className="fa fa-users" style={{ paddingRight: "10px" }}></i>
            {slovarBesed["EVN-1"]?.naziv}
          </h3>
        </Col>
      </Row>
      {dogodki.map((dogodek) => {
        return (
          <div className="card mb-4 card-event">
            <div className="card-body">
              <h5 className="card-title">{dogodek.naslov}</h5>
              <h6 id="EVN-2" className="card-subtitle mb-2 ">
                <span className="text-muted">
                  {slovarBesed["EVN-2"]?.naziv}
                </span>{" "}
                {dogodek.izvajalec}
              </h6>
              <p className="card-text">
                <span id="EVN-3" className="text-muted">
                  {slovarBesed["EVN-3"]?.naziv}{" "}
                </span>
                {dogodek.datum === dogodek.datum_konec
                  ? `${new Date(dogodek.datum).toLocaleDateString("sl-SI", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}`
                  : `${new Date(dogodek.datum).toLocaleDateString("sl-SI", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })} - ${new Date(dogodek.datum_konec).toLocaleDateString(
                      "sl-SI",
                      {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      }
                    )} `}
                <br />
                <span id="EVN-4" className="text-muted">
                  {slovarBesed["EVN-4"]?.naziv}{" "}
                </span>
                {dogodek.ura.replace(/:\d{2}$/, "")} -{" "}
                {dogodek.ura_konec.replace(/:\d{2}$/, "")}
                <br />
                <span id="EVN-5" className="text-muted">
                  {" "}
                  {slovarBesed["EVN-5"]?.naziv}{" "}
                </span>
                {dogodek.prostor}
              </p>
              <span
                className="card-text"
                dangerouslySetInnerHTML={{
                  __html: dogodek.opis,
                }}
              />
              {dogodek.prijavljen && (
                <span className="ms-1">
                  {" "}
                  <a
                    id="EVN-14"
                    href={`${process.env.REACT_APP_DOGODKI_URL}Strani/usposabljanje2.aspx?dogodekID=${dogodek.pkDogodekID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {slovarBesed["EVN-14"]?.naziv}{" "}
                  </a>
                </span>
              )}

              {dogodek.prijavljen && (
                <>
                  <p className="mt-3 card-text" id="ENV-6">
                    <span id="EVN-6" style={{ color: "#FF5C00" }}>
                      {slovarBesed["EVN-6"]?.naziv}{" "}
                      {/*Na dogodek ste prijavljeni. */}
                    </span>
                    <span id="EVN-15">{slovarBesed["EVN-15"]?.naziv} </span>
                    {/*Pregled prijav je na voljo na:*/}
                    <span>
                      <a
                        id="ENV-10"
                        href={`${process.env.REACT_APP_DOGODKI_URL}PrijavaDI/Strani/mojidogodki.aspx`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {slovarBesed["EVN-10"]?.naziv}
                      </a>
                    </span>{" "}
                  </p>
                  {dogodek.link !== "" && (
                    <p className=" mt-3 card-text " id="ENV-12">
                      {slovarBesed["EVN-12"]?.naziv}{" "}
                      <span>
                        <a
                          id="ENV-13"
                          href={dogodek.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {slovarBesed["EVN-13"]?.naziv}
                        </a>
                      </span>
                    </p>
                  )}
                </>
              )}
              {!dogodek.prijavljen && (
                <p className=" mt-3 card-text">
                  <span>
                    <a
                      id="ENV-11"
                      href={`${process.env.REACT_APP_DOGODKI_URL}Strani/usposabljanje2.aspx?dogodekID=${dogodek.pkDogodekID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {slovarBesed["EVN-11"]?.naziv}{" "}
                    </a>
                  </span>
                </p>
              )}
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default EventsPage;
