import {
    InteractionRequiredAuthError,
    LogLevel,
    PublicClientApplication,
    BrowserCacheLocation
  } from "@azure/msal-browser";
  
  export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MSAL_CLIENTID, // Client ID
      authority: process.env.REACT_APP_MSAL_AUTHORITY, // Tenant ID of the React.JS App Registration
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage, // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {  
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              //console.error(message);
              return;
            case LogLevel.Info:
              //console.info(message);
              return;
            case LogLevel.Verbose:
              //console.debug(message);
              return;
            case LogLevel.Warning:
              //console.warn(message);
              return;
          }
        },
      },
    },
  };
  
  export const acquireAPItoken = async () => {
    const msalInstance = new PublicClientApplication(msalConfig);
    const accounts = msalInstance.getAllAccounts();
  
    const request = {
      ...loginApiRequest,
      account: accounts[0],
    };
  
    try {
      const authResult = await msalInstance.acquireTokenSilent(request);
      return authResult.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        const authResult = await msalInstance.acquireTokenPopup(request);
        return authResult.accessToken;
      }
    }
  };
  
  
  
  // Can be found in the API Permissions of the ASP.NET Web API
  export const loginApiRequest = {
    scopes: [process.env.REACT_APP_MSAL_APISCOPE],
  };
  
  // Get Acess Token with User Data
  export const userRead = {
    scopes: ["User.Read"],
  };
  
  // Get Acess Token with User Data and extra Azure settings
  export const userReadWithExtraQuerys = {
    scopes: ["User.Read"],
    extraQueryParameters: { domain_hint: process.env.REACT_APP_MSAL_HINT, prompt: "login" }
  };
  
  // extra Azure settings
  export const extraQuerys = {
    extraQueryParameters: { domain_hint: process.env.REACT_APP_MSAL_HINT}
  };
  
