import React from 'react'
import { Card, Row, Col } from 'react-bootstrap';

const ScheduleList = ({userSchedules, deleteScheduleHandler, slovarBesed}) => {
    return (
        userSchedules.map((schedules) => {
            return (
                <Card key={schedules.id} className="m-4" style={{borderColor: "#ccc", borderLeft: "5px solid #777", borderRadius: "0.1em"}}>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Card.Title>{schedules.fakultetaID}</Card.Title>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card.Text>
                                            <div id="SCL-1" style={{color: "#888"}}>{slovarBesed["SCL-1"]?.naziv} <span style={{color: "#333", textTransform: "uppercase"}}>{schedules.programNaziv.replace(/ *\([^)]*\) */g, '')}</span></div>
                                        </Card.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card.Text>
                                            <div id="SCL-2" style={{color: "#888"}}>{slovarBesed["SCL-2"]?.naziv} <span style={{color: "#333", textTransform: "uppercase"}}>{schedules.letnik}</span></div>
                                        </Card.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card.Text>
                                            <div id="SCL-3" style={{color: "#888"}}>{slovarBesed["SCL-3"]?.naziv} <span style={{color: "#333", textTransform: "uppercase"}}>{schedules.smerNaziv.replace(/ *\([^)]*\) */g, '')}</span></div>
                                        </Card.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card.Text style={{color: "#888"}}><span id="SCL-4">{slovarBesed["SCL-4"]?.naziv} </span>
                                        {
                                            schedules.vseSkupine === true ?

                                            <span id="SCL-5" style={{color: "#333"}}>{slovarBesed["SCL-5"]?.naziv}</span> :

                                            <div style={{display: "inline", color: "#333"}}>
                                                
                                                {
                                                    schedules.skupine.map(function (item)
                                                    {
                                                        return (
                                                            <span className="skupine-item">{item.naziv}</span>
                                                        );
                                                    })
                                                }
                                                
                                            </div>
                                        }
                                        </Card.Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="align-items-center justify-content-end d-flex" xs="auto">
                                <i id="SCL-6" onClick={(e) => deleteScheduleHandler(schedules)} className="fa fa-trash-o fa-2x" aria-hidden="true" title={slovarBesed["SCL-6"]?.naziv}></i>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            );
        })
    )
}

export default ScheduleList;