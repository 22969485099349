import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default class HomePage extends Component {
  render() {
    const { slovarBesed } = this.props;

    return (
      <>
        <div style={{ marginTop: "100px" }}>
          <Container fluid>
            <Row>
              <Col>
                <NavLink to="/koledar" className="jumbotron-text-color">
                  <div
                    className="jumbotron"
                    style={{ color: "#333", background: "#e9ecef" }}
                  >
                    <Row>
                      <Col className="col-custom">
                        <i
                          className="fa fa-calendar fa-2x"
                          aria-hidden="true"
                        ></i>
                      </Col>
                      <Col>
                        <h4
                          id="HMP-1"
                          style={{ margin: "0", paddingTop: "3px" }}
                        >
                          {slovarBesed["HMP-1"]?.naziv}
                        </h4>
                      </Col>
                    </Row>
                  </div>
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <NavLink to="/storitve" className="jumbotron-text-color">
                  <div
                    className="jumbotron"
                    style={{ color: "#333", background: "#e9ecef" }}
                  >
                    <Row>
                      <Col className="col-custom">
                        <i
                          className="fa fa-database fa-2x"
                          aria-hidden="true"
                        ></i>
                      </Col>
                      <Col>
                        <h4
                          id="HMP-2"
                          style={{ margin: "0", paddingTop: "2px" }}
                        >
                          {slovarBesed["HMP-2"]?.naziv}
                        </h4>
                      </Col>
                    </Row>
                  </div>
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <NavLink to="/dogodki" className="jumbotron-text-color">
                  <div
                    className="jumbotron"
                    style={{ color: "#333", background: "#e9ecef" }}
                  >
                    <Row>
                      <Col className="col-custom">
                        <i className="fa fa-users fa-2x" aria-hidden="true"></i>
                      </Col>
                      <Col>
                        <h4
                          id="HMP-4 "
                          style={{ margin: "0", paddingTop: "2px" }}
                        >
                          {slovarBesed["HMP-5"]?.naziv}
                        </h4>
                      </Col>
                    </Row>
                  </div>
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <NavLink to="/novice" className="jumbotron-text-color">
                  <div
                    className="jumbotron"
                    style={{ color: "#333", background: "#e9ecef" }}
                  >
                    <Row>
                      <Col className="col-custom">
                        <i className="fa fa-book fa-2x" aria-hidden="true"></i>
                      </Col>
                      <Col>
                        <h4
                          id="HMP-3"
                          style={{ margin: "0", paddingTop: "2px" }}
                        >
                          {slovarBesed["HMP-3"]?.naziv}
                        </h4>
                      </Col>
                    </Row>
                  </div>
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <NavLink to="/navodila" className="jumbotron-text-color">
                  <div
                    className="jumbotron"
                    style={{ color: "#333", background: "#e9ecef" }}
                  >
                    <Row>
                      <Col className="col-auto col-custom">
                        <i
                          className="fa fa-info-circle fa-2x"
                          aria-hidden="true"
                        ></i>
                      </Col>
                      <Col>
                        <h4
                          id="HMP-4 "
                          style={{ margin: "0", paddingTop: "2px" }}
                        >
                          {slovarBesed["HMP-4"]?.naziv}
                        </h4>
                      </Col>
                    </Row>
                  </div>
                </NavLink>
              </Col>
            </Row>

            <br />
            <br />
            <br />
            <br />
          </Container>
        </div>
      </>
    );
  }
}
