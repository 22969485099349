import React from 'react'
import { Card, Row, Col } from 'react-bootstrap';

const UcneEnoteList = ({ userUcneEnote, deleteUcneEnoteHandler, slovarBesed }) => {
   return (
    userUcneEnote.map((ucneEnote) => {
        return (
            <Card key={ucneEnote.id} className="m-4" style={{borderColor: "#ccc", borderLeft: "5px solid #888", borderRadius: "0.15em"}}>
                <Card.Body>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Card.Title>{ucneEnote.fakultetaID}</Card.Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card.Text style={{textTransform: "uppercase"}}>
                                        {ucneEnote.ucnaEnotaNaziv.replace(/ *\([^)]*\) */g, '')}
                                    </Card.Text>
                                </Col>
                            </Row>

                        </Col>
                        <Col className="align-items-center  justify-content-end d-flex" xs="auto">
                            <i id="UCN-1" onClick={(e) => deleteUcneEnoteHandler(ucneEnote)} className="fa fa-trash-o fa-2x" aria-hidden="true" title={slovarBesed["UCN-1"]?.naziv}></i>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    })
   )
}

export default UcneEnoteList; 