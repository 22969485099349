
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'

const Footer = ({slovarBesed}) => {

    return (
        <div className="fixed-bottom footer">
            <Container fluid style={{padding: "0"}}>
                <Col className="bottom-nav" lg={5}>
                    <Row className="text-center">
                        <Col style={{padding: "0"}}>
                            <NavLink to="/" className="bottom-footer-col">
                                <Row>
                                    <Col><i className="fa fa-home footer-icon"></i></Col>
                                </Row>
                                <Row>
                                    <Col id="FTR-1">{slovarBesed["FTR-1"]?.naziv}</Col>
                                </Row>
                            </NavLink>
                        </Col>
                        <Col style={{padding: "0"}}>
                            <NavLink to="/koledar" className="bottom-footer-col">
                                <Row>
                                    <Col><i className="fa fa-calendar footer-icon"></i></Col>
                                </Row>
                                <Row>
                                <Col id="FTR-2">{slovarBesed["FTR-2"]?.naziv}</Col>
                                </Row>
                            </NavLink>
                        </Col>
                        <Col style={{padding: "0"}}>
                            <NavLink to="/storitve" className="bottom-footer-col">
                                <Row>
                                    <Col><i className="fa fa-database footer-icon"></i></Col>
                                </Row>
                                <Row>
                                <Col id="FTR-3">{slovarBesed["FTR-3"]?.naziv}</Col>
                                </Row>
                            </NavLink>
                        </Col>
                        <Col style={{padding: "0"}}>
                            <NavLink to="/nastavitve" className="bottom-footer-col">
                                <Row>
                                    <Col><i className="fa fa-cogs footer-icon"></i></Col>
                                </Row>
                                <Row>
                                <Col id="FTR-4">{slovarBesed["FTR-4"]?.naziv}</Col>
                                </Row>
                            </NavLink>
                        </Col>
                    </Row>
                </Col>
            </Container>
        </div >
    )
}

export default Footer;
