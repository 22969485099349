
import React, { Component } from 'react'
import LoadingAnimation from '../LoadingAnimation'
import Collapsible from 'react-collapsible';
import Moment from 'react-moment';

import { fetchGET } from '../../apiHelper';

export default class NewsPage extends Component {
    state =
        {
            novice: [],
            loading: true
        };

    componentDidMount() {
        this.getNovice();
    }
    getNovice = async () => {
        try {
            const response = await fetchGET(`${process.env.REACT_APP_ApiDomain}` + `/api/student/rss1`)
            this.setState(
                {
                    novice: response,
                    loading: false
                });

        } catch (error) {
            console.log("Prišlo je do napake");
        }
    }

    render() {
        const { slovarBesed } = this.props;
        

        return (
            <>
                { /* LOADER */}

                {this.state.loading === true
                    ? <LoadingAnimation />
                    : <>
                        <div id="news-page" style={{ marginTop: "50px", marginBottom: "50px", padding: "10px" }}>
                            <h3 id="NWS-1" style={{ margin: "10px 0 30px 20px" }}><i className="fa fa-book" style={{ marginRight: "10px" }}></i>{slovarBesed["NWS-1"]?.naziv}</h3>


                            { /* SEZNAM NOVIC */}

                            <div>

                                {this.state.novice.map(item =>
                                    <Collapsible easing="ease-in-out" trigger=
                                        {<div>
                                            <div className="news-header">
                                                <span id="NWS-2">{slovarBesed["NWS-2"]?.naziv} <Moment format="D.M.YYYY">{item.datum}</Moment></span>
                                                <span>{item.fakulteta}</span>
                                            </div>
                                            <div className="news-title">
                                                <div style={{ marginRight: "12px" }}>{item.naslov}</div>
                                                <i className="fa fa-chevron-down"></i>
                                            </div>
                                        </div>}>
                                        <div>{item.opis.replace(/(<([^>]+)>)/ig, '')}</div>
                                        <a id="NWS-3" href={item.link} target="_blank" rel="noopener noreferrer" style={{ color: "#1b92bb" }}>{slovarBesed["NWS-3"]?.naziv}</a>
                                    </Collapsible>)}
                            </div>
                        </div>
                    </>}
            </>
        )
    }
}
