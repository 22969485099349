
import React from 'react'
import {Bars} from 'react-loader-spinner'

const LoadingAnimation = () =>
{
    return (
        
        <div align="center" style={{marginTop: "80px", display:'flex', justifyContent:"center"}}>
            <Bars type="Bars" color="lightgrey" height={50} width={50} />
        </div>
    )
}

export default LoadingAnimation;
