
import React from 'react'

const MessageNoScheduleData = ({link, slovarBesed}) => {
    return (
        <div className="no-schedule-block" style={{background: "#dc3545", color: "white"}}>
            <h6 id="MNS-1">{slovarBesed["MNS-1"]?.naziv} <a id="MNS-2" href={link} style={{color: "white"}} target="_blank" rel="noopener noreferrer">{slovarBesed["MNS-2"]?.naziv}</a></h6>
        </div>
    )
}

export default MessageNoScheduleData
