import React from 'react'
import { Card, Form, Button } from 'react-bootstrap';
import Select from 'react-select'
import { Context } from './../../SettingsPage'

const FormScheduleSelect = ({slovarBesed}) => {
    let {
        dropDownValues,
        dropDownSelectedValue,
        toggleButtons,
        postData,
        formHandlers
    } = React.useContext(Context)

    return (
       
        <Card className="m-4 mrg0">
            <Card.Body>
                <Form onSubmit={postData.postSchedule}>
                    <Form.Group>
                        <Form.Label id="FSS-1">{slovarBesed["FSS-1"]?.naziv}</Form.Label>
                        < Select options={dropDownValues.faculties} isSearchable={false} onChange={formHandlers.handleChangeDropDownFaculties} value={dropDownSelectedValue.selectedFacultie} />
                    </Form.Group>
                    {!toggleButtons.hiddeDropdowns && <>
                        {!toggleButtons.hiddeOnLoadingProgram && 
                        <Form.Group>
                            <Form.Label id="FSS-2">{slovarBesed["FSS-2"]?.naziv}</Form.Label>
                            < Select options={dropDownValues.programs} isSearchable={false} onChange={formHandlers.handleChangeDropDownPrograms} value={dropDownSelectedValue.selectedProgram} />
                        </Form.Group>
                        }
                        {!toggleButtons.hiddeOnLoadingYears &&
                        <Form.Group>
                            <Form.Label id="FSS-3">{slovarBesed["FSS-3"]?.naziv}</Form.Label>
                            < Select options={dropDownValues.programYears} isSearchable={false} onChange={formHandlers.handleChangeDropDownProgramYear} value={dropDownSelectedValue.selectedProgramYear} />
                        </Form.Group>
                        }
                        {!toggleButtons.hiddeOnLoadingCourseAndButton && <>
                        <Form.Group>
                            <Form.Label id="FSS-4">{slovarBesed["FSS-4"]?.naziv}</Form.Label>
                            < Select options={dropDownValues.courses} isSearchable={false} onChange={formHandlers.handleChangeDropDownCourse} value={dropDownSelectedValue.selectedCourse} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label id="FSS-5">{slovarBesed["FSS-5"]?.naziv}</Form.Label>
                            < Select isMulti placeholder={<div id="FSS-6">{slovarBesed["FFS-6"]?.naziv}</div>} isSearchable={false} options={dropDownValues.skupine} onChange={formHandlers.handleChangeDropDownSkupine} value={dropDownSelectedValue.selectedSkupine} />
                        </Form.Group>
                        <Button id="FSS-7" className='mt-3' type="submit">{slovarBesed["FSS-7"]?.naziv}</Button>
                        </>
                        }
                    </>
                    }
                </Form>
            </Card.Body>
        </Card>
    )
}

export default FormScheduleSelect
