
import React, { Component } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap';
import LoadingAnimation from '../LoadingAnimation'

import { fetchGET } from '../../apiHelper';

export default class ServicesPage extends Component {
    state =
        {
            dataAktivniSistemi: {},
            dataFinancniPodatki: [],
            popup: false,
            loading: true
        };

    componentDidMount() {
        this.getAktivniSistemi();
    }


    getAktivniSistemi = async () => {
        try {
            const response = await fetchGET(`${process.env.REACT_APP_ApiDomain}` + `/api/student/aktivni_sistemi`)
            this.setState({ dataAktivniSistemi: response }, this.getFinancniPodatki);

        } catch (error) {
            console.log("Prišlo je do napake");
        }
    }

    getFinancniPodatki = async () => {
        try {
            const response = await fetchGET(`${process.env.REACT_APP_ApiDomain}` + `/api/student/studiji_aips`)
            this.setState(
                {
                    dataFinancniPodatki: response,
                    loading: false
                });

        } catch (error) {
            console.log("Prišlo je do napake");
        }
    }

    openPopup = () => this.setState({ popup: true });
    closePopup = () => this.setState({ popup: false });

    render() {
        const { slovarBesed } = this.props;

        return (
            <>
                {
                    //  LOADER  //
                    this.state.loading === true ?

                        //  IF - true  //
                        <LoadingAnimation /> :

                        //  IF - false  //
                        <>
                            <Container>
                                <Row>
                                    <Col>

                                        <h3 id="SRV-1" style={{ marginLeft: "20px", marginTop: "10px", marginBottom: "20px" }}><i className="fa fa-database" style={{ paddingRight: "10px" }}></i>{slovarBesed["SRV-1"]?.naziv}</h3>

                                        <div className='jumbotron jumbotron-services'>

                                            <h6 id="SRV-2" style={{ fontSize: "18px" }}>{slovarBesed["SRV-2"]?.naziv}</h6>

                                            {
                                                this.state.dataAktivniSistemi.status === "No data!" ?

                                                    <div>Ni podatkov!</div> :

                                                    <div>
                                                        <div id="SRV-3">{this.state.dataAktivniSistemi.aipSweb === true ? <i className="fa fa-check-circle green"></i> : <i className="fa fa-times-circle red"></i>} {slovarBesed["SRV-3"]?.naziv}</div>
                                                        <div id="SRV-4">{this.state.dataAktivniSistemi.aipSizpit === true ? <i className="fa fa-check-circle green"></i> : <i className="fa fa-times-circle red"></i>} {slovarBesed["SRV-4"]?.naziv}</div>
                                                        <div id="SRV-5">{this.state.dataAktivniSistemi.moodle === true ? <i className="fa fa-check-circle green"></i> : <i className="fa fa-times-circle red"></i>} {slovarBesed["SRV-5"]?.naziv}</div>
                                                        <div id="SRV-6">{this.state.dataAktivniSistemi.eduroam === true ? <i className="fa fa-check-circle green"></i> : <i className="fa fa-times-circle red"></i>} {slovarBesed["SRV-6"]?.naziv}</div>
                                                        <div id="SRV-7">{this.state.dataAktivniSistemi.office365 === true ? <i className="fa fa-check-circle green"></i> : <i className="fa fa-times-circle red"></i>} {slovarBesed["SRV-7"]?.naziv}</div>
                                                        <div id="SRV-8">{this.state.dataAktivniSistemi.dkum === true ? <i className="fa fa-check-circle green"></i> : <i className="fa fa-times-circle red"></i>} {slovarBesed["SRV-8"]?.naziv}</div>
                                                    </div>
                                            }

                                        </div>

                                        <div className='jumbotron jumbotron-services' style={{ marginBottom: "100px", cursor: "pointer" }} onClick={this.openPopup}>

                                            <h4 id="SRV-9" style={{ margin: "10px 0", fontSize: "20px" }}>{slovarBesed["SRV-9"]?.naziv}</h4>

                                            {
                                                this.state.dataFinancniPodatki.status === "No data!" ?

                                                    <div id="SRV-10">{slovarBesed["SRV-10"]?.naziv}</div> :

                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                        <span>
                                                            {
                                                                parseFloat(this.state.dataFinancniPodatki[0].dolgUM) > 2 &&

                                                                <div id="SRV-11">{slovarBesed["SRV-11"]?.naziv} {this.state.dataFinancniPodatki[0].dolgUM} €</div>
                                                            }
                                                        </span>

                                                        {this.state.dataFinancniPodatki[0].barva === "clRed" &&
                                                            <div style={{ display: "flex", marginTop: "-34px" }}>
                                                                <div className="status-semafor clRed"></div>
                                                                <div className="status-semafor clYellow" style={{ background: "white", border: "3px solid #eae191" }}></div>
                                                                <div className="status-semafor clGreen" style={{ background: "white", border: "3px solid #b8dcbb" }}></div>
                                                            </div>}

                                                        {this.state.dataFinancniPodatki[0].barva === "clYellow" &&
                                                            <div style={{ display: "flex", marginTop: "-34px" }}>
                                                                <div className="status-semafor clRed" style={{ background: "white", border: "3px solid #ebcccd" }}></div>
                                                                <div className="status-semafor clYellow"></div>
                                                                <div className="status-semafor clGreen" style={{ background: "white", border: "3px solid #b8dcbb" }}></div>
                                                            </div>}

                                                        {this.state.dataFinancniPodatki[0].barva === "clGreen" &&
                                                            <div style={{ display: "flex", marginTop: "-34px" }}>
                                                                <div className="status-semafor clRed" style={{ background: "white", border: "3px solid #ebcccd" }}></div>
                                                                <div className="status-semafor clYellow" style={{ background: "white", border: "3px solid #eae191" }}></div>
                                                                <div className="status-semafor clGreen"></div>
                                                            </div>}

                                                    </div>
                                            }

                                        </div>

                                        <Modal centered show={this.state.popup} onHide={this.closePopup} animation={true}>
                                            <Modal.Header closeButton>
                                                <Modal.Title id="SRV-12 example-modal-sizes-title-sm">
                                                {slovarBesed.length >0 ? slovarBesed[67].naziv : ""}
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="modal-body-content-details">
                                                <div style={{ fontSize: "16px" }}>
                                                    <span id="SRV-13" style={{ background: "#0c970c", color: "white", padding: "1px 5px", borderRadius: "3px", marginRight: "8px" }}>{slovarBesed["SRV-13"]?.naziv}</span ><span id="SRV-14">{slovarBesed["SRV-14"]?.naziv}</span><br /><br />

                                                    <span id="SRV-15" style={{ background: "#e1ce2c", color: "white", padding: "1px 5px", borderRadius: "3px", marginRight: "8px" }}>{slovarBesed["SRV-15"]?.naziv}</span><span id="SRV-16">{slovarBesed["SRV-16"]?.naziv}</span> <br /><br />

                                                    <span id="SRV-17" style={{ background: "#d85454", color: "white", padding: "1px 5px", borderRadius: "3px", marginRight: "8px" }}>{slovarBesed["SRV-17"]?.naziv}</span><span id="SRV-17">{slovarBesed["SRV-18"]?.naziv}</span>
                                                </div>
                                            </Modal.Body>
                                        </Modal>

                                    </Col>
                                </Row>
                            </Container>
                        </>
                }
            </>
        )
    }
}
