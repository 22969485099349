import React, { Component } from "react";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import listGridPlugin from "@fullcalendar/list";
import "../../customCalendar.css";
import Select from "react-select";

import { fetchGET, fetchPOST } from "../../apiHelper";

import { Modal, Row, Col, Container, Form } from "react-bootstrap";

import LoadingAnimation from "../LoadingAnimation";
import Switch from "react-switch";

import slLocale from "@fullcalendar/core/locales/sl";
import enLocale from "@fullcalendar/core/locales/en-gb";

import { NavLink } from "react-router-dom";

import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import sl from "date-fns/locale/sl";

registerLocale("sl", sl);

export default class SchedulePage extends Component {
  state = {
    studyInfo: [],
    filterStudy: [],
    uniqueStudi: [],
    selectedOption: "",
    events: [],
    isHiddentScheduleSidebar: true,
    subjectInfo: {},
    userSetSchedule: "",
    isSettingSLinkHidden: true,
    loading: true,
    koledarBtn: false,
    dayView: true,
    pogled: "",
    niUrnika: false,
    jezik: "",
  };

  calendarRef = React.createRef();

  componentDidMount() {
    this.getJezik();
    this.getNastavitveAplikacije();
  }

  getJezik = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` + `/api/student/jezik`
      );
      this.setState({ jezik: response });
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getNastavitveAplikacije = async () => {
    try {
      const response = await fetchGET(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/nastavitve_aplikacije`
      );

      if (response.status !== "No data!") {
        let pogled;
        let koledarBtn;
        let dayView;

        response.forEach((item) => {
          if (item.privzetPogled === 1) {
            pogled = "timeGridDay";
            dayView = true;
          }

          if (item.privzetPogled === 2) {
            pogled = "timeGridWeek";
            dayView = false;
            koledarBtn = true;
          }

          if (item.privzetPogled === 3) {
            pogled = "listWeek";
            dayView = false;
            koledarBtn = false;
          }
        });

        this.setState(
          {
            pogled: pogled,
            dayView: dayView,
            koledarBtn: koledarBtn,
            niUrnika: response[0].niUrnika,
            nastavitevPrazniki: response[0].prazniki,
            nastavitevPrijavnice: response[0].prijavnice,
            nastavitevZapadlosti: response[0].zapadlosti,
            nastavitevVstopi: response[0].vstopi,
            nastavitevDogodki: response[0].uMdogodki,
          },
          this.getStudyInfoByUpn
        );
      } else {
        this.getStudyInfoByUpn();
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  getStudyInfoByUpn = async () => {
    const body = {
      prazniki: this.state.nastavitevPrazniki,
      prijavnice: this.state.nastavitevPrijavnice,
      zapadlosti: this.state.nastavitevZapadlosti,
      vstopi: this.state.nastavitevVstopi,
      uMdogodki: this.state.nastavitevDogodki,
    };

    try {
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` + `/api/urnik`,
        body
      );
      if (Array.isArray(response)) {
        this.setState(
          {
            studyInfo: response,
            filterStudy: response,
            isSettingSLinkHidden: true,
            loading: false,
          },
          this.sortEvents
        );
      }
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  sortEvents = () => {
    let unique = Array.from(
      new Set(
        this.state.studyInfo
          .map((item) => item.extendedProps.urnik.course)
          .filter((course) => course !== null)
      )
    );

    const { slovarBesed } = this.props;

    if (unique.length > 1) {
      unique.unshift(slovarBesed["SCH-34"]?.naziv);
      this.setState({ niUrnika: false });
    } else if (unique.length === 1) {
      this.setState({ niUrnika: false });
    }

    const uniqueItems = [];
    unique.forEach((element) => {
      uniqueItems.push({ value: element, label: element });
    });

    this.setState({ uniqueStudi: uniqueItems });
    this.setState({ selectedOption: uniqueItems[0] });
  };

  handleChangeDropDown = (selected) => {
    this.setState({ selectedOption: selected }, () => {
      if (this.state.selectedOption.value === "VSI") {
        const array = [...this.state.studyInfo];
        this.setState({ filterStudy: array });
      } else {
        const array = [...this.state.studyInfo];
        const filteredArray = array.filter(
          (x) =>
            x.extendedProps.urnik.course === this.state.selectedOption.value
        );
        this.setState({ filterStudy: filteredArray });
      }

      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.render();
    });
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  handleCloseNiUrnika = () => this.setState({ niUrnika: false });

  hideModalDetails = () => {
    this.setState({ showDetails: false });
  };
  showModalDetails = () => {
    this.setState({ showDetails: true });
  };

  handleChange = (date) => {
    this.setState({ date: date });

    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.gotoDate(date);

    this.handleClose();
  };

  switchTodayWiew = () => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("timeGridDay");
    this.setState({
      isHiddentScheduleSidebar: !this.state.isHiddentScheduleSidebar,
      dayView: true,
    });
  };
  switchTolistWeekView = () => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("listWeek");
    this.setState({
      isHiddentScheduleSidebar: !this.state.isHiddentScheduleSidebar,
      koledarBtn: false,
      dayView: false,
    });
  };
  switchToGridWeekView = () => {
    let calendarApi = this.calendarRef.current.getApi();

    if (this.state.koledarBtn !== true) calendarApi.changeView("timeGridWeek");
    else calendarApi.changeView("listWeek");

    this.setState({ koledarBtn: !this.state.koledarBtn });
  };
  switchToDatePicker = () => {
    this.handleShow();
    this.setState({
      isHiddentScheduleSidebar: !this.state.isHiddentScheduleSidebar,
    });
  };

  toggleScheduleSiddebar = () => {
    this.setState({
      isHiddentScheduleSidebar: !this.state.isHiddentScheduleSidebar,
    });
  };

  toggleToToday = () => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.today();
  };

  eventClickDetails = (info) => {
    this.setState({
      subjectInfo: {
        start: info.event.start,
        end: info.event.end,
        title: info.event.title,
        class: info.event.classNames,
        izvajalec: info.event.extendedProps.lecturers,
        prostor: info.event.extendedProps.rooms,
        ucnaEnota:
          info.event.extendedProps.course +
          " - " +
          info.event.extendedProps.executionType,
        ucnaEnota1: info.event.extendedProps.course,
        opomba: info.event.extendedProps.note,
        zapadlost: info.event.extendedProps.zapadlost,
        z_datum: info.event.extendedProps.zapadlost.datum,
        z_sklic: info.event.extendedProps.zapadlost.sklic,
        z_znesek: info.event.extendedProps.zapadlost.znesek,
        z_namen: info.event.extendedProps.zapadlost.namen,
        z_trr: info.event.extendedProps.zapadlost.trr,
        st: info.event.extendedProps.urnik.st,
      },
    });
    this.showModalDetails();
  };

  hideNiUrnika = async () => {
    const p1 = `state=` + `false`;

    try {
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/shrani_pogled2` +
          `?${p1}`
      );
      this.getNastavitveAplikacije();
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  /* Nastavitev prikaza posemeznih enot urnika in izpisa dinamičnih podatkov */
  renderEventUrnik = (info, element) => {
    const { slovarBesed } = this.props;

    if (info.view.type === "timeGridDay" || info.view.type === "timeGridWeek") {
      return (
        <>
          {/* Urnik */}
          {info.event.extendedProps.urnik.st < 99 && (
            <div className="fc-content">
              <div className="fc-title" style={{ color: "#006385" }}>
                {info.event.extendedProps.course} (
                {info.event.extendedProps.executionType})
              </div>
              <div className="fc-title">
                <Moment format="H.mm">{info.event.start}</Moment> –{" "}
                <Moment format="H.mm">{info.event.end}</Moment>,{" "}
                {info.event.extendedProps.rooms[0]}
              </div>
              <div className="fc-title">
                {info.event.extendedProps.noteShort.length > 0 && (
                  <span style={{ color: "red", fontWeight: "900" }}>!</span>
                )}
                {info.event.extendedProps.noteShort}
              </div>
            </div>
          )}

          {/* Št koledar */}
          {info.event.extendedProps.urnik.st === "99" && (
            <div className="fc-content">
              <div className="fc-title" style={{ color: "#4caf50" }}>
                {info.event.title}
              </div>
            </div>
          )}

          {/* Prijavnice */}
          {info.event.extendedProps.urnik.st === "100" && (
            <div className="fc-content">
              <div className="fc-title" style={{ color: "#9c27b0" }}>
                {info.event.title}
              </div>
            </div>
          )}

          {/* Plačila */}
          {info.event.extendedProps.urnik.st === "101" && (
            <div className="fc-content">
              <div
                id="SCH-29"
                className="fc-title"
                style={{ color: "#bf0000" }}
              >
                {slovarBesed["SCH-29"]?.naziv}
              </div>
            </div>
          )}

          {/* Diploma oddaja, diploma zagovor */}
          {(info.event.extendedProps.urnik.st === "103" ||
            info.event.extendedProps.urnik.st === "104") && (
            <div className="fc-content">
              <div className="fc-title" style={{ color: "#bf0000" }}>
                {info.event.title}
              </div>
            </div>
          )}

          {/*Dogodki */}
          {info.event.extendedProps.urnik.st === "106" && (
            <div className="fc-content">
              <div className="fc-title" style={{ color: "#850063" }}>
                {info.event.title.toUpperCase()}
              </div>
            </div>
          )}
        </>
      );
    }
    if (info.view.type === "listWeek") {
      return (
        <>
          {/* Urnik  */}
          {info.event.extendedProps.urnik.st < 99 && (
            <>
              <div className="fc-list-item-title fc-widget-content">
                <div>
                  <span
                    className="fc-event-dot"
                    style={{ background: "#3788d8", marginRight: "20px" }}
                  ></span>
                  {info.event.extendedProps.course} -{" "}
                  {info.event.extendedProps.executionType} (
                  {info.event.extendedProps.rooms[0]})
                </div>
                {info.event.extendedProps.noteShort && (
                  <div>
                    <span style={{ color: "red" }}>!</span>
                    {info.event.extendedProps.noteShort}
                  </div>
                )}
              </div>
            </>
          )}

          {info.event.extendedProps.urnik.st === "99" && (
            <div className="fc-list-item-title fc-widget-content">
              <div>
                <span
                  className="fc-event-dot"
                  style={{ background: "#4caf50", marginRight: "20px" }}
                ></span>
                {info.event.title}
              </div>
            </div>
          )}

          {/* Prijavnice */}
          {info.event.extendedProps.urnik.st === "100" && (
            <div className="fc-list-item-title fc-widget-content">
              <div>
                <span
                  className="fc-event-dot"
                  style={{ background: "#9c27b0", marginRight: "20px" }}
                ></span>
                {info.event.title}
              </div>
            </div>
          )}

          {/* Plačila */}
          {info.event.extendedProps.urnik.st === "101" && (
            <div className="fc-list-item-title fc-widget-content">
              <div>
                <span
                  id="SCH-30"
                  className="fc-event-dot"
                  style={{ background: "#cd2424", marginRight: "20px" }}
                ></span>
                <span style={{ color: "#bf0000" }}>
                  {slovarBesed["SCH-30"]?.naziv}
                </span>
              </div>
            </div>
          )}

          {/* Diplomska oddaja, diplomski zagovor */}
          {(info.event.extendedProps.urnik.st === "103" ||
            info.event.extendedProps.urnik.st === "104") && (
            <div className="fc-list-item-title fc-widget-content">
              <div>
                <span
                  className="fc-event-dot"
                  style={{ background: "#cd2424", marginRight: "20px" }}
                ></span>
                {info.event.title}
              </div>
            </div>
          )}

          {/*Dogodki */}
          {info.event.extendedProps.urnik.st === "106" && (
            <div className="fc-list-item-title fc-widget-content">
              <div>
                <span
                  className="fc-event-dot"
                  style={{ background: "#33b2b2", marginRight: "20px" }}
                ></span>
                {info.event.title}
              </div>
            </div>
          )}
        </>
      );
    }
  };

  render() {
    const { slovarBesed } = this.props;
    return (
      <div>
        {
          //  LOADER  //
          this.state.loading === true ? (
            //  IF - true  //
            <LoadingAnimation />
          ) : (
            //  IF - false  //
            //!this.state.isScheduleComponentHidden &&
            <>
              {/*Modalno okno za izbiro datuma*/}
              <div style={{ paddingTop: "15px" }}>
                <Modal
                  centered
                  size="sm"
                  show={this.state.show}
                  onHide={this.handleClose}
                  animation={true}
                >
                  <Modal.Body className="modal-body-content">
                    <DatePicker
                      selected={this.state.date}
                      onSelect={this.handleSelect} //when day is clicked
                      onChange={this.handleChange} //only when value has changed
                      className="datepicker-modal"
                      locale={this.state.jezik === "slo" ? "sl" : "en"}
                      inline
                    />
                  </Modal.Body>
                </Modal>

                {this.state.niUrnika && (
                  <div>
                    <Modal
                      centered
                      show={this.state.niUrnika}
                      onHide={this.handleCloseNiUrnika}
                      animation={true}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm SCH-1">
                          {slovarBesed["SCH-1"]?.naziv}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-content-details">
                        <div id="SCH-2" style={{ fontSize: "16px" }}>
                          {slovarBesed["SCH-2"]?.naziv}
                          <br />
                          <br />
                          <span id="SCH-3">
                            {" "}
                            {slovarBesed["SCH-3"]?.naziv}{" "}
                          </span>{" "}
                          <NavLink id="SCH-4" to="/nastavitve">
                            {" "}
                            {slovarBesed["SCH-4"]?.naziv}
                          </NavLink>
                          .
                          <br />
                          <br />
                          <br />
                          <Form.Check
                            id="obvestilo SCH-5"
                            custom
                            type="checkbox"
                            label={slovarBesed["SCH-5"]?.naziv}
                            checked={!this.state.niUrnika}
                            onChange={this.hideNiUrnika}
                          />
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    margin: "0 30px 15px 30px",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 id="SCH-6">
                    <i
                      className="fa fa-calendar"
                      style={{
                        paddingRight: "10px",
                        position: "relative",
                        top: "-2px",
                      }}
                    ></i>{" "}
                    {slovarBesed["SCH-6"]?.naziv}
                  </h3>
                  {!this.state.dayView && (
                    <Switch
                      onColor="#888"
                      offColor="#888"
                      onChange={this.switchToGridWeekView}
                      checked={this.state.koledarBtn}
                      checkedIcon={
                        <div className="calendar-btn">
                          <i className="fa fa-list"></i>
                        </div>
                      }
                      uncheckedIcon={
                        <div className="calendar-btn">
                          <i className="fa fa-calendar"></i>
                        </div>
                      }
                    />
                  )}
                </div>
                <div className="schedule-dropdown" style={{ margin: "0 10px" }}>
                  <Select
                    id="SCH-7"
                    className="ddm1"
                    isSearchable={false}
                    options={this.state.uniqueStudi}
                    onChange={this.handleChangeDropDown}
                    value={this.state.selectedOption}
                    placeholder={slovarBesed["SCH-7"]?.naziv}
                  />
                </div>
                <hr />
                <div>
                  {this.state.subjectInfo.st !== "99" && (
                    <Modal
                      centered
                      show={this.state.showDetails}
                      onHide={this.hideModalDetails}
                      animation={true}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm SCH-8">
                          {" "}
                          {slovarBesed["SCH-8"]?.naziv}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-content-details">
                        <div>
                          {(this.state.subjectInfo.z_datum !== null ||
                            this.state.subjectInfo.izvajalec !== null) && (
                            <Container fluid>
                              {parseInt(this.state.subjectInfo.st) < 99 && (
                                <Row>
                                  <Col sm={4} id="SCH-9">
                                    {slovarBesed["SCH-9"]?.naziv}
                                  </Col>
                                  <Col sm={8}>
                                    <span className="font-weight-bold">
                                      {this.state.subjectInfo.ucnaEnota}
                                    </span>
                                  </Col>
                                </Row>
                              )}

                              {this.state.subjectInfo.st === "106" && (
                                <Row>
                                  <Col sm={4} id="SCH-35">
                                    {slovarBesed["SCH-35"]?.naziv}
                                  </Col>
                                  <Col sm={8}>
                                    <span className="font-weight-bold">
                                      {this.state.subjectInfo.title}
                                    </span>
                                  </Col>
                                </Row>
                              )}

                              {this.state.subjectInfo.izvajalec !== null &&
                                this.state.subjectInfo.izvajalec !== "" && (
                                  <Row>
                                    <Col sm={4} id="SCH-10">
                                      {slovarBesed["SCH-10"]?.naziv}
                                    </Col>
                                    <Col sm={8} className="font-weight-bold">
                                      {this.state.subjectInfo.izvajalec}
                                    </Col>
                                  </Row>
                                )}

                              {this.state.subjectInfo.prostor !== null && (
                                <Row>
                                  <Col sm={4} id="SCH-11">
                                    {slovarBesed["SCH-11"]?.naziv}
                                  </Col>
                                  <Col sm={8} className="font-weight-bold">
                                    {this.state.subjectInfo.prostor}
                                  </Col>
                                </Row>
                              )}

                              {this.state.subjectInfo.z_datum === null && (
                                <>
                                  <Row>
                                    <Col id="SCH-12" sm={4}>
                                      {slovarBesed["SCH-12"]?.naziv}
                                    </Col>
                                    <Col sm={8} className="font-weight-bold">
                                      <Moment format="H.mm">
                                        {this.state.subjectInfo.start}
                                      </Moment>{" "}
                                      –{" "}
                                      <Moment format="H.mm">
                                        {this.state.subjectInfo.end}
                                      </Moment>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col id="SCH-13" sm={4}>
                                      {slovarBesed["SCH-13"]?.naziv}
                                    </Col>
                                    <Col sm={8} className="font-weight-bold">
                                      <Moment format="D. M. YYYY">
                                        {this.state.subjectInfo.start}
                                      </Moment>
                                    </Col>
                                  </Row>
                                </>
                              )}

                              {this.state.subjectInfo.z_datum !== null && (
                                <>
                                  <Row>
                                    <Col id="SCH-14" sm={8}>
                                      {slovarBesed["SCH-14"]?.naziv}{" "}
                                      <span className="font-weight-bold">
                                        {this.state.subjectInfo.z_namen}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col id="SCH-15" sm={12}>
                                      {slovarBesed["SCH-15"]?.naziv}{" "}
                                      <span className="font-weight-bold">
                                        {this.state.subjectInfo.z_datum}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col id="SCH-16" sm={8}>
                                      {slovarBesed["SCH-16"]?.naziv}{" "}
                                      <span className="font-weight-bold">
                                        {this.state.subjectInfo.z_sklic}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col id="SCH-17" sm={8}>
                                      {slovarBesed["SCH-17"]?.naziv}{" "}
                                      <span className="font-weight-bold">
                                        {this.state.subjectInfo.z_znesek} €
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col id="SCH-18" sm={8}>
                                      {slovarBesed["SCH-18"]?.naziv}{" "}
                                      <span className="font-weight-bold">
                                        {this.state.subjectInfo.z_trr}
                                      </span>
                                    </Col>
                                  </Row>
                                </>
                              )}

                              {this.state.subjectInfo.opomba !== "" &&
                                this.state.subjectInfo.opomba !== null &&
                                parseInt(this.state.subjectInfo.st) < 99 && (
                                  <Row>
                                    <Col id="SCH-19" sm={4}>
                                      {slovarBesed["SCH-19"]?.naziv}
                                    </Col>
                                    <Col sm={8} className="font-weight-bold">
                                      {this.state.subjectInfo.opomba}
                                    </Col>
                                  </Row>
                                )}
                            </Container>
                          )}

                          {this.state.subjectInfo.st === "103" && (
                            <Container fluid>
                              <Row>
                                <Col id="SCH-20" sm={4}>
                                  {slovarBesed["SCH-20"]?.naziv}
                                </Col>
                                <Col sm={8} className="font-weight-bold">
                                  <Moment format="D. M. YYYY">
                                    {this.state.subjectInfo.start}
                                  </Moment>
                                </Col>
                              </Row>
                              <Row>
                                <Col id="SCH-21" sm={4}>
                                  {slovarBesed["SCH-21"]?.naziv}
                                </Col>
                                <Col sm={8} className="font-weight-bold">
                                  {this.state.subjectInfo.ucnaEnota1}
                                </Col>
                              </Row>
                            </Container>
                          )}

                          {this.state.subjectInfo.st === "104" && (
                            <Container fluid>
                              <Row>
                                <Col id="SCH-22" sm={4}>
                                  {slovarBesed["SCH-22"]?.naziv}
                                </Col>
                                <Col sm={8} className="font-weight-bold">
                                  <Moment format="D. M. YYYY">
                                    {this.state.subjectInfo.start}
                                  </Moment>
                                </Col>
                              </Row>
                              <Row>
                                <Col id="SCH-23" sm={4}>
                                  {slovarBesed["SCH-23"]?.naziv}
                                </Col>
                                <Col sm={8} className="font-weight-bold">
                                  {this.state.subjectInfo.ucnaEnota1}
                                </Col>
                              </Row>
                              <Row>
                                <Col id="SCH-24" sm={4}>
                                  {slovarBesed["SCH-24"]?.naziv}
                                </Col>
                                <Col sm={8} className="font-weight-bold">
                                  {this.state.subjectInfo.prostor}
                                </Col>
                              </Row>
                              <Row>
                                <Col id="SCH-25" sm={4}>
                                  {slovarBesed["SCH-25"]?.naziv}
                                </Col>
                                <Col sm={8} className="font-weight-bold">
                                  <Moment format="H.mm">
                                    {this.state.subjectInfo.start}
                                  </Moment>
                                </Col>
                              </Row>
                            </Container>
                          )}

                          {this.state.subjectInfo.opomba !== "" &&
                            this.state.subjectInfo.opomba !== null && (
                              <Container>
                                <Row className="mt-3">
                                  <Col
                                    sm={12}
                                    id="SCH-37"
                                    className="text-secondary "
                                  >
                                    {slovarBesed["SCH-37"]?.naziv}{" "}
                                    <span>
                                      <a
                                        href={this.state.subjectInfo.opomba}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {slovarBesed["SCH-38"]?.naziv}{" "}
                                      </a>
                                    </span>
                                  </Col>
                                </Row>
                              </Container>
                            )}
                        </div>
                      </Modal.Body>
                    </Modal>
                  )}
                </div>

                <div>
                  <FullCalendar
                    ref={this.calendarRef}
                    initialView={this.state.pogled} /* privzeti pogled */
                    allDaySlot={false} /* allDay odstranje iz pogleda */
                    plugins={[
                      timeGridPlugin,
                      listGridPlugin,
                    ]} /*izbran plugin za pogled */
                    themeSystem="standard"
                    headerToolbar={{
                      /*Postavitev gumnbov v hederj-u */ left: "prev",
                      center: "title",
                      right: "next",
                    }}
                    views={{
                      timeGridDay: {
                        displayEventTime: false,
                        displayEventEnd: false,
                      },
                      timeGridWeek: {
                        slotDuration: "00:15",
                      },
                    }}
                    locales={[slLocale, enLocale]} /* Slovenski prevod */
                    locale={
                      this.state.jezik === "slo" ? "sl" : "en"
                    } /* Slovenski prevod */
                    events={this.state.filterStudy}
                    hiddenDays={[0]} /*  0 - skrij nedeljo */
                    eventClick={this.eventClickDetails}
                    slotEventOverlap={false}
                    slotMinTime="07:00:00"
                    slotMaxTime="22:00:00"
                    slotDuration="00:20"
                    slotLabelInterval="01:00"
                    height="auto"
                    eventContent={this.renderEventUrnik}
                  />
                </div>
                <div
                  onClick={this.toggleToToday}
                  className="schedule-button-today"
                >
                  <i className="fa fa-circle-o-notch" aria-hidden="true"></i>
                </div>
                <div
                  onClick={this.toggleScheduleSiddebar}
                  className="schedule-sidebar-button"
                >
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>
                {!this.state.isHiddentScheduleSidebar && (
                  <div
                    className="schedule-sidebar-menu"
                    onTouchCancel={this.toggleScheduleSiddebar}
                  >
                    <Container fluid>
                      <Row className="text-center">
                        <Col onClick={this.switchTodayWiew}>
                          <Row>
                            <Col>
                              <i
                                className="fa fa-columns"
                                aria-hidden="true"
                                style={{ fontSize: "1.5em" }}
                              ></i>
                            </Col>
                          </Row>
                          <Row>
                            <Col id="SCH-31">
                              {slovarBesed["SCH-31"]?.naziv}
                            </Col>
                          </Row>
                        </Col>
                        <Col onClick={this.switchTolistWeekView}>
                          <Row>
                            <Col>
                              <i
                                className="fa fa-list-alt"
                                aria-hidden="true"
                                style={{ fontSize: "1.5em" }}
                              ></i>
                            </Col>
                          </Row>
                          <Row>
                            <Col id="SCH-32">
                              {slovarBesed["SCH-32"]?.naziv}
                            </Col>
                          </Row>
                        </Col>
                        <Col onClick={this.switchToDatePicker}>
                          <Row>
                            <Col>
                              <i
                                className="fa fa-calendar-o"
                                aria-hidden="true"
                                style={{ fontSize: "1.5em" }}
                              ></i>
                            </Col>
                          </Row>
                          <Row>
                            <Col id="SCH-33">
                              {slovarBesed["SCH-33"]?.naziv}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                )}
              </div>
            </>
          )
        }
      </div>
    );
  }
}
