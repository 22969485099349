import React, { Component } from "react";
import { Navbar, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { fetchPOST } from "../apiHelper";

/* Logo import*/
import logotipum from "./../logotipum.png";

export default class Header extends Component {
  state = {
    obvestilaVidena: false,
  };

  // Prilagodi username za izpis
  usernameData = getFirstletters(this.props.msalContext.accounts[0].username);

  handleLogout = () => {
    let id = this.props.msalContext.accounts[0].homeAccountId;
    const currentAccount =
      this.props.msalContext.instance.getAccountByHomeId(id);
    const logoutHint = currentAccount.idTokenClaims.login_hint;

    this.props.msalContext.instance.logoutRedirect({
      account: currentAccount,
      logoutHint: logoutHint,
    });
  };

  setObvestilaVidena = async () => {
    try {
      const response = await fetchPOST(
        `${process.env.REACT_APP_ApiDomain}` +
          `/api/student/notifications_videno`
      );
    } catch (error) {
      console.log("Prišlo je do napake");
    }
  };

  render() {
    const { slovarBesed, obvestilaVidena } = this.props;

    return (
      <div className="header-div">
        <Navbar fixed="top" bg="dark" variant="dark" className="navbar-custom">
          <Col lg={5} md={12} sm={12} xs={12} className="top-nav">
            <Navbar.Brand className="header-brand">
              <NavLink to="/">
                <img
                  src={logotipum}
                  height="35"
                  className="d-inline-block align-top"
                  alt="logo univerza"
                />
              </NavLink>
            </Navbar.Brand>

            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <NavLink
                to="/obvestila"
                onClick={() => {
                  this.setObvestilaVidena();
                  this.props.funkcijaPreveriObvestila();
                }}
              >
                <div style={{ marginRight: "15px", position: "relative" }}>
                  {!obvestilaVidena.videno && (
                    <span className="notification-dot"></span>
                  )}
                  <i
                    className="fa fa-bell"
                    style={{
                      color: "white",
                      fontSize: "24px",
                      marginTop: "3px",
                    }}
                  ></i>
                </div>
              </NavLink>
              <div
                className="profile-initials"
                title={this.usernameData.imePriimek}
              >
                <span>{this.usernameData.firstLettersOfUsername}</span>
              </div>
              <Navbar.Text>
                <a
                  onClick={this.handleLogout}
                  id="HDR-1"
                  className="text-decoration-none"
                  href="#login"
                >
                  {slovarBesed["HDR-1"]?.naziv}
                </a>
              </Navbar.Text>
            </Navbar.Collapse>
          </Col>
        </Navbar>
      </div>
    );
  }
}

export const getFirstletters = (username) => {
  let splitUsername = username.split(/[.@]/, 2);
  let firstLettersOfUsername = (
    splitUsername[0].charAt(0) + splitUsername[1].charAt(0)
  ).toUpperCase();
  let imePriimek = username.split("@")[0].replace(".", " ").toUpperCase();

  return { firstLettersOfUsername, imePriimek };
};
